var en = {
    auth: {
        invalidPhoneNumber: 'Sorry, your number does not seem to be registered yet. Please contact your administrator to do this for you first.',
        greeting: 'Hey {{name}},',
        mainGreeting: 'Welcome to {{appName}}.',
        identifyYourself: 'To log in, please identify yourself.',
        codeSent: 'We have sent you a login code via SMS to',
        change: 'Change...',
        enterCode: 'Enter the code received in SMS',
        phoneNumber: '',
        phoneNumberPlaceholder: 'Please enter your phone number',
        signIn: 'Sign in',
        requestCode: 'Request code',
        code: 'Code',
        search: 'Search...',
    },
    floatingBar: {
        ticketsSelected: 'Tickets selected',
        ticketsSent: 'Tickets sent',
        assignToMe: 'Assign to me',
        assignTo: 'Assign to...',
        assignToAgent: 'Assign to agent',
        assign: 'Assign',
        send: 'Send',
        assignError: 'Error while sending tickets',
        noTicketsSelected: 'No tickets selected',
        placeholders: {
            phoneNumber: 'Phone number',
            firstName: 'First name',
            lastName: 'Last name',
        },
        smsNotificationInfo: 'Recipient will be notified by SMS.',
        agentNotFound: 'Agent not found',
    },
    error: {
        phoneNumberReq: 'Phone number is required',
        phoneNumberInv: 'Invalid phone number',
        codeReq: 'Code is required',
        codeInv: 'Invalid code',
        loginFailed: 'Login failed',
        general: 'Something went wrong, please try again later.',
    },
    header: {
        pageName: {
            eventList: 'Event List',
            assign: 'Assign tickets',
            manage: 'Manage assigned tickets',
        },
    },
    eventCard: {
        ticketsAllocated: 'Tickets Allocated',
        assigned: 'Assigned',
        remaining: 'Remaining',
        manageButton: 'Manage',
        assignButton: 'Assign',
    },
    tableFilter: {
        singulars: {
            ticketType: 'Ticket Type',
            qrCode: 'QR Code',
        },
        clearFilter: 'Clear filter',
        refresh: 'Refresh',
        emptySelection: 'Clear selection',
        nullValue: '-',
    },
    table: {
        tableHeader: {
            ticketId: 'ticket id',
            headerFields: 'fields',
            ticketTypeName: 'ticket type',
            qrCode: 'QR Code',
            name: 'name',
            phoneNumber: 'number',
            status: 'status',
        },
        noDataText: {
            overFiltered: 'No matching tickets.',
            assignDefault: 'All tickets already assigned.',
            manageDefault: 'No tickets were assigned yet.',
        },
        startMultiSelectText: {
            default: 'Start selection',
            active: 'Tap on range end',
        },
    },
    errorCodes: {
        general: 'Something went wrong, please try again later',
        AGENT_NOT_FOUND: 'No agent was found with this phone number.',
        LOGIN_FAILED: 'Login failed.',
    },
    assignPage: {
        noSeatingMap: 'No seating map for this event.',
    },
};
export default en;
