import React from 'react';
import Loadable from 'react-loadable';
import { Button, LinearProgress } from '@material-ui/core';
var LoadingComponent = function (props) {
    var error = props.error, retry = props.retry, timedOut = props.timedOut, pastDelay = props.pastDelay;
    if (error) {
        return React.createElement("div", null,
            "Error! ",
            React.createElement(Button, { onClick: retry }, "Retry"));
    }
    if (timedOut) {
        return React.createElement("div", null,
            "Taking a long time... ",
            React.createElement(Button, { onClick: retry }, "Retry"));
    }
    if (pastDelay) {
        return React.createElement(LinearProgress, null);
    }
    return null;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (function (importFunc) { return Loadable({
    loader: importFunc,
    loading: LoadingComponent,
}); });
