var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { Provider } from 'mobx-react';
import { createStores } from 'stores';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import theme from './theme';
var BootstrapComponent = function (_a) {
    var children = _a.children;
    return (React.createElement(DefaultErrorBoundary, null,
        React.createElement(Provider, __assign({}, createStores()),
            React.createElement(React.Fragment, null,
                React.createElement(CssBaseline, null),
                React.createElement(MuiThemeProvider, { theme: theme }, children)))));
};
export default BootstrapComponent;
