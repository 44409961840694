var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ErrorComponent from 'components/Error';
var DefaultErrorBoundary = /** @class */ (function (_super) {
    __extends(DefaultErrorBoundary, _super);
    function DefaultErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // eslint-disable-next-line react/state-in-constructor
        _this.state = {
            isError: false,
        };
        return _this;
    }
    DefaultErrorBoundary.getDerivedStateFromError = function () {
        return { isError: true };
    };
    // TODO: log errors to sentry
    // public componentDidCatch(error: Error | null, info: object) {
    //   if (!ENV_PRODUCTION) {
    //     return;
    //   }
    //   logComponentStackToSentryPlaceholder(error, info);
    // }
    DefaultErrorBoundary.prototype.render = function () {
        var isError = this.state.isError;
        var children = this.props.children;
        return isError ? React.createElement(ErrorComponent, null) : children;
    };
    return DefaultErrorBoundary;
}(React.Component));
export default DefaultErrorBoundary;
