var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
var PrivateRoute = function (_a) {
    var Comp = _a.component, isAuthenticated = _a.isAuthenticated, path = _a.path, exact = _a.exact, rest = __rest(_a, ["component", "isAuthenticated", "path", "exact"]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var routeComponent = function (props) { return (isAuthenticated
        ? React.createElement(Comp, __assign({}, props))
        : React.createElement(Redirect, { to: { pathname: '/auth' } })); };
    return React.createElement(Route, __assign({ path: path, exact: exact }, rest, { render: routeComponent }));
};
export default PrivateRoute;
