var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable, action, reaction, computed } from 'mobx';
import i18next from 'i18next';
import { API } from './api';
import handleClientError from 'utils/handleClientError';
import phoneUtil from 'utils/phone';
var AuthStore = /** @class */ (function () {
    function AuthStore() {
        var _this = this;
        this.agentApi = new API.AgentApi();
        this.isLoggedIn = false;
        this.tokenExpiresAt = undefined;
        this.agentId = -1;
        this.inProgress = false;
        this.code = '';
        this.phoneNationalNumber = '';
        this.phoneCountryCode = '';
        this.agentCountryCode = '';
        this.agentPhoneNumber = '';
        this.error = undefined;
        this.configApi = new API.ConfigApi();
        if (this.isLoggedIn) {
            // void this.checkSession();
        }
        reaction(function () { var _a; return [(_a = _this.token) === null || _a === void 0 ? void 0 : _a.id]; }, function () {
            var _a;
            _this.agentApi.setToken(((_a = _this.token) === null || _a === void 0 ? void 0 : _a.id) || '');
        });
    }
    AuthStore.prototype.fetchConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var COUNTRY_CODE, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!process.env.APP_TYPE) {
                            this.setPhoneCountryCode('');
                            return [2 /*return*/, ''];
                        }
                        return [4 /*yield*/, this.configApi.getAppConfig("".concat(process.env.APP_TYPE))];
                    case 1:
                        COUNTRY_CODE = (_a.sent()).COUNTRY_CODE;
                        this.setPhoneCountryCode(COUNTRY_CODE || '');
                        return [2 /*return*/, COUNTRY_CODE || ''];
                    case 2:
                        err_1 = _a.sent();
                        this.setPhoneCountryCode('');
                        return [2 /*return*/, ''];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthStore.prototype.setError = function (val) {
        this.error = val;
    };
    AuthStore.prototype.setPhoneNumber = function (phoneNumber) {
        var _a = phoneUtil.parsePhoneNumber(phoneNumber), nationalNumber = _a.nationalNumber, countryCode = _a.countryCode;
        this.phoneNationalNumber =
            nationalNumber[0] === '0' ? nationalNumber.substring(1) : nationalNumber;
        this.phoneCountryCode = countryCode || this.phoneCountryCode;
    };
    AuthStore.prototype.setPhoneCountryCode = function (countryCode) {
        this.phoneCountryCode = countryCode;
    };
    AuthStore.prototype.setAgentPhoneNumber = function () {
        this.agentPhoneNumber = this.phoneNumber;
    };
    AuthStore.prototype.setAgentCountryCode = function (countryCode) {
        this.agentCountryCode = countryCode;
    };
    Object.defineProperty(AuthStore.prototype, "phoneNumber", {
        get: function () {
            return phoneUtil.getNationalNumber(this.phoneNationalNumber || '', this.phoneCountryCode || '');
        },
        enumerable: false,
        configurable: true
    });
    AuthStore.prototype.setCode = function (val) {
        this.code = val;
    };
    AuthStore.prototype.resetScreenState = function () {
        this.inProgress = false;
        this.code = '';
        this.error = undefined;
    };
    AuthStore.prototype.requestCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp, e_1, e2_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.phoneNumber)
                            throw new Error(i18next.t('error.phoneNumberReq'));
                        this.inProgress = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 8, 9]);
                        return [4 /*yield*/, this.agentApi.agentSendVerificationCode({
                                phoneNumber: this.phoneNumber,
                            })];
                    case 2:
                        resp = _a.sent();
                        this.verifiedName = resp.name;
                        return [3 /*break*/, 9];
                    case 3:
                        e_1 = _a.sent();
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, handleClientError(e_1)];
                    case 5: throw _a.sent();
                    case 6:
                        e2_1 = _a.sent();
                        throw new Error(i18next.t('errorCodes.general'));
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        this.inProgress = false;
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    AuthStore.prototype.login = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp, e_2, e2_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // if (!this.jwt) throw new Error('No jwt token');
                        if (!this.code)
                            throw new Error(i18next.t('error.codeReq'));
                        if (!this.phoneNumber)
                            throw new Error(i18next.t('error.phoneNumberReq'));
                        this.inProgress = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 8, 9]);
                        return [4 /*yield*/, this.agentApi.agentLogin({
                                phoneNumber: this.phoneNumber,
                                password: this.code,
                            })];
                    case 2:
                        resp = _a.sent();
                        this.token = resp.token;
                        this.agentId = resp.agentId;
                        this.verifiedName = resp.name;
                        this.accountIds = resp.accountIds;
                        this.isLoggedIn = true;
                        return [3 /*break*/, 9];
                    case 3:
                        e_2 = _a.sent();
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, handleClientError(e_2)];
                    case 5: throw _a.sent();
                    case 6:
                        e2_2 = _a.sent();
                        throw new Error(i18next.t('errorCodes.general'));
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        this.inProgress = false;
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    AuthStore.prototype.logout = function () {
        this.fetchConfig();
        this.token = undefined;
        this.isLoggedIn = false;
        this.tokenExpiresAt = undefined;
        this.agentId = -1;
    };
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], AuthStore.prototype, "agentApi", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], AuthStore.prototype, "isLoggedIn", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], AuthStore.prototype, "token", void 0);
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], AuthStore.prototype, "tokenExpiresAt", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], AuthStore.prototype, "agentId", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], AuthStore.prototype, "accountIds", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], AuthStore.prototype, "inProgress", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], AuthStore.prototype, "code", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], AuthStore.prototype, "verifiedName", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], AuthStore.prototype, "phoneNationalNumber", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], AuthStore.prototype, "phoneCountryCode", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], AuthStore.prototype, "agentCountryCode", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], AuthStore.prototype, "agentPhoneNumber", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], AuthStore.prototype, "error", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], AuthStore.prototype, "configApi", void 0);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], AuthStore.prototype, "fetchConfig", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "setError", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "setPhoneNumber", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "setPhoneCountryCode", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "setAgentPhoneNumber", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "setAgentCountryCode", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], AuthStore.prototype, "phoneNumber", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "setCode", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "resetScreenState", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], AuthStore.prototype, "requestCode", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], AuthStore.prototype, "login", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AuthStore.prototype, "logout", null);
    return AuthStore;
}());
export { AuthStore };
export default AuthStore;
