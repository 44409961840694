var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import { withStores } from 'stores';
import AuthContainer from 'containers/Auth';
import MainContainer from 'containers/Main';
// eslint-disable-next-line react/prefer-stateless-function
var Routes = /** @class */ (function (_super) {
    __extends(Routes, _super);
    function Routes() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Routes.prototype.render = function () {
        var authStore = this.props.authStore;
        return (React.createElement(Router, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/auth", component: AuthContainer }),
                React.createElement(PrivateRoute, { path: "/main", isAuthenticated: authStore.isLoggedIn, component: MainContainer }),
                React.createElement(Redirect, { to: "/main" }))));
    };
    return Routes;
}(React.Component));
export default withStores('authStore')(Routes);
