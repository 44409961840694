var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createMuiTheme, hexToRgb } from '@material-ui/core';
import './AugmentThemeOptions';
var primaryColor = process.env.PRIMARY_COLOR || '#433A73';
export function createAppTheme(options) {
    return createMuiTheme(__assign({ palette: {
            primary: {
                main: primaryColor,
                contrastText: '#FFF',
            },
            secondary: {
                main: '#FFF',
                dark: '#E8E8E8',
                light: '#F8F8F8',
                contrastText: '#888888',
            },
            text: {
                primary: '#888888',
                secondary: '#A0A0A0',
                disabled: 'rgba(112, 112, 112, 0.5)',
            },
            background: {
                default: '#F8F8F8',
                paper: '#FFF',
            },
        }, appDrawer: {
            width: 225,
        }, typography: {
            fontFamily: 'Open Sans, Roboto, Helvetica, Arial',
        }, overrides: {
            MuiButton: {
                root: {
                    borderRadius: '24px',
                    textTransform: 'none',
                    fontWeight: 'normal',
                    paddingTop: '14px',
                    paddingBottom: '12px',
                    marginBottom: '8px',
                    lineHeight: '22px',
                },
                contained: {
                    boxShadow: 'none',
                },
            },
            MuiFormControl: {
                root: {
                    backgroundColor: '#FFF',
                    borderRadius: '24px',
                    border: '1px solid #D0D0D0',
                },
            },
            MuiInput: {
                underline: {
                    '&:before': {
                        borderBottom: 0,
                    },
                    '&:after': {
                        borderBottom: 0,
                    },
                    '&:hover:not($disabled):before': {
                        borderBottom: 0,
                    },
                },
            },
            MuiListItem: {
                root: {
                    '&$selected': {
                        backgroundColor: 'transparent',
                        color: primaryColor,
                        fontWeight: 'bold',
                    },
                    '&$selected:hover': {
                        backgroundColor: 'transparent',
                        color: primaryColor,
                        fontWeight: 'bold',
                    },
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                button: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
            MuiMenuItem: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            MuiTableRow: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#FFF',
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: '#F0F0F0',
                    },
                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: hexToRgb(primaryColor).replace(')', ', 0.2)'),
                    },
                    '&.Mui-selected': {
                        backgroundColor: primaryColor,
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: hexToRgb(primaryColor).replace(')', ', 0.8)'),
                        },
                    },
                },
            },
            MuiCheckbox: {
                colorSecondary: {
                    '&.Mui-checked': {
                        color: 'inherit',
                    },
                },
            },
            MuiMenu: {
                paper: {
                    borderRadius: 16,
                },
            },
        } }, options));
}
export default createAppTheme({});
