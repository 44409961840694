var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import _ from "lodash";
import axios from 'axios';
import "reflect-metadata";
var requiredMetadataKey = Symbol("required");
var pathParamMetadataKey = Symbol("pathparam");
var queryParamMetadataKey = Symbol("queryparam");
var headerParamMetadataKey = Symbol("headerparam");
var bodyMetadataKey = Symbol("body");
var apiMethodMetadataKey = Symbol("apimethod");
var addToMetadataCollection = function (metadataKey, target, propName, newValue) {
    var existingList = Reflect.getOwnMetadata(metadataKey, target, propName) || [];
    existingList.push(newValue);
    Reflect.defineMetadata(metadataKey, existingList, target, propName);
};
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(method, arg) {
        var _this = _super.call(this, "Required parameter '".concat(arg, "' was null or undefined when calling '").concat(method, "'.")) || this;
        _this.method = method;
        _this.arg = arg;
        _this.name = "RequiredError";
        return _this;
    }
    return RequiredError;
}(Error));
export { RequiredError };
var NotSupportedError = /** @class */ (function (_super) {
    __extends(NotSupportedError, _super);
    function NotSupportedError(message) {
        var _this = _super.call(this, message) || this;
        _this.message = message;
        _this.name = "NotSupportedError";
        return _this;
    }
    return NotSupportedError;
}(Error));
export { NotSupportedError };
export var Required = function (argName) { return function (target, methodName, argIndex) {
    addToMetadataCollection(requiredMetadataKey, target, methodName, { argIndex: argIndex, argName: argName });
}; };
export var PathParam = function (paramName) { return function (target, methodName, argIndex) {
    addToMetadataCollection(pathParamMetadataKey, target, methodName, { argIndex: argIndex, paramName: paramName });
    Required(paramName)(target, methodName, argIndex);
}; };
export var QueryParam = function (paramName) { return function (target, methodName, argIndex) {
    addToMetadataCollection(queryParamMetadataKey, target, methodName, { argIndex: argIndex, paramName: paramName });
}; };
export var HeaderParam = function (paramName) { return function (target, methodName, argIndex) {
    addToMetadataCollection(headerParamMetadataKey, target, methodName, { argIndex: argIndex, paramName: paramName });
}; };
export var Body = function (paramName) { return function (target, methodName, argIndex) {
    var existingMetadata = Reflect.getOwnMetadata(bodyMetadataKey, target, methodName);
    if (existingMetadata)
        throw new NotSupportedError("Multiple body not supported: '".concat(existingMetadata.paramName, "'<->'").concat(paramName, "' at method '").concat(methodName.toString(), "'"));
    Reflect.defineMetadata(bodyMetadataKey, { argIndex: argIndex, paramName: paramName }, target, methodName);
}; };
export var ApiMethod = function (apiMethodMetadata) { return function (target, methodName, descriptor) {
    var method = descriptor.value;
    descriptor.value = function () {
        // Check required arguments
        var pathParameters = Reflect.getOwnMetadata(pathParamMetadataKey, target, methodName);
        var queryParameters = Reflect.getOwnMetadata(queryParamMetadataKey, target, methodName);
        var headerParameters = Reflect.getOwnMetadata(headerParamMetadataKey, target, methodName);
        var body = Reflect.getOwnMetadata(bodyMetadataKey, target, methodName);
        var requiredParameters = Reflect.getOwnMetadata(requiredMetadataKey, target, methodName);
        if (requiredParameters) {
            for (var _i = 0, requiredParameters_1 = requiredParameters; _i < requiredParameters_1.length; _i++) {
                var _a = requiredParameters_1[_i], argIndex = _a.argIndex, argName = _a.argName;
                if (argIndex >= arguments.length ||
                    arguments[argIndex] === undefined ||
                    arguments[argIndex] === null) {
                    throw new RequiredError(methodName, argName);
                }
            }
        }
        Reflect.defineMetadata(apiMethodMetadataKey, apiMethodMetadata, this, methodName);
        Reflect.defineMetadata(pathParamMetadataKey, pathParameters, this, methodName);
        Reflect.defineMetadata(queryParamMetadataKey, queryParameters, this, methodName);
        Reflect.defineMetadata(headerParamMetadataKey, headerParameters, this, methodName);
        Reflect.defineMetadata(bodyMetadataKey, body, this, methodName);
        var result = method.apply(this, arguments);
        Reflect.deleteMetadata(apiMethodMetadataKey, this);
        Reflect.deleteMetadata(pathParamMetadataKey, this);
        Reflect.deleteMetadata(queryParamMetadataKey, this);
        Reflect.deleteMetadata(headerParamMetadataKey, this);
        Reflect.deleteMetadata(bodyMetadataKey, this);
        return result;
    };
}; };
export var getFilledUrl = function (target, methodName, args) {
    var pathParams = Reflect.getOwnMetadata(pathParamMetadataKey, target, methodName) || [];
    var apiMethodMetadata = Reflect.getOwnMetadata(apiMethodMetadataKey, target, methodName);
    return pathParams
        .map(function (_a) {
        var argIndex = _a.argIndex, paramName = _a.paramName;
        return [paramName, args[argIndex]];
    })
        .reduce(function (url, _a) {
        var paramName = _a[0], paramValue = _a[1];
        return url.replace("{".concat(paramName, "}"), paramValue);
    }, apiMethodMetadata.url);
};
export var getQueryParams = function (target, methodName, args) {
    var queryParams = Reflect.getOwnMetadata(queryParamMetadataKey, target, methodName) || [];
    return _.fromPairs(queryParams
        .map(function (x) { return [x.paramName, args[x.argIndex]]; })
        .filter(function (_a) {
        var _k = _a[0], v = _a[1];
        return v !== undefined;
    }));
};
export var getHeaderParams = function (target, methodName, args) {
    var headerParams = Reflect.getOwnMetadata(headerParamMetadataKey, target, methodName) || [];
    return _.fromPairs(headerParams
        .map(function (x) { return [x.paramName, args[x.argIndex]]; })
        .filter(function (_a) {
        var _k = _a[0], v = _a[1];
        return v !== undefined;
    }));
};
export var getBody = function (target, methodName, args) {
    var body = Reflect.getOwnMetadata(bodyMetadataKey, target, methodName);
    if (body) {
        return args[body.argIndex] || {};
    }
    else
        return null;
};
export var getApiMethodMetadata = function (target, methodName, args) {
    return Reflect.getOwnMetadata(apiMethodMetadataKey, target, methodName) || {};
};
var BASE_PATH = (process.env.API_ROOT || 'http://localhost:4000/api').replace(/\/+$/, "");
var BaseApi = /** @class */ (function () {
    function BaseApi(newToken) {
        if (newToken === void 0) { newToken = ''; }
        this.baseURL = BASE_PATH;
        this.authToken = '';
        this.setToken(newToken);
    }
    ;
    BaseApi.prototype.setToken = function (newToken) {
        this.authToken = newToken;
    };
    ;
    return BaseApi;
}());
export { BaseApi };
var ExampleApi = /** @class */ (function (_super) {
    __extends(ExampleApi, _super);
    function ExampleApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExampleApi.prototype.exampleMethod = function (exampleId, query1, dto) {
        var url = getFilledUrl(this, 'exampleMethod', arguments);
        var queryParams = getQueryParams(this, 'exampleMethod', arguments);
        var headerParams = getHeaderParams(this, 'exampleMethod', arguments);
        var body = getBody(this, 'exampleMethod', arguments);
        var config = getApiMethodMetadata(this, 'exampleMethod', arguments);
        return axios.request({
            baseURL: this.baseURL,
            headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
            data: body,
            params: queryParams,
            url: url,
            method: config.method
        });
    };
    __decorate([
        ApiMethod({
            url: '/Managers/{managerId}',
            method: 'GET'
        }),
        __param(0, PathParam('managerId')),
        __param(1, QueryParam('query1')),
        __param(2, Body('dto')),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Object, Object]),
        __metadata("design:returntype", void 0)
    ], ExampleApi.prototype, "exampleMethod", null);
    return ExampleApi;
}(BaseApi));
export { ExampleApi };
