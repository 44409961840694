import React from 'react';
import { Button, Card, CardContent, Typography, CardActions, createStyles, withStyles } from '@material-ui/core';
var styles = function (theme) { return createStyles({
    root: {
        margin: 'auto',
        marginTop: theme.spacing(4),
        maxWidth: '600px',
    },
}); };
var reload = function () {
    window.location.reload();
};
var ErrorComponent = function (_a) {
    var classes = _a.classes;
    return (React.createElement(Card, { className: classes.root },
        React.createElement(CardContent, null,
            React.createElement(Typography, { variant: "h3", component: "h1", gutterBottom: true }, "Something went wrong."),
            React.createElement(Typography, { variant: "body1" }, "We apologize for the inconvenience. Problems are reported automatically and we will try to resolve it as soon as possible.")),
        React.createElement(CardActions, null,
            React.createElement(Button, { size: "small", onClick: reload }, "Reload the page"))));
};
export default withStyles(styles)(ErrorComponent);
