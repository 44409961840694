var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import uuidv4 from 'uuid/v4';
import { observable } from 'mobx';
var FlashMessage = /** @class */ (function () {
    function FlashMessage(content, duration) {
        var _this = this;
        this.open = true;
        this.close = function () {
            _this.open = false;
        };
        this.content = content;
        this.duration = duration;
        this.id = uuidv4();
    }
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], FlashMessage.prototype, "open", void 0);
    return FlashMessage;
}());
export default FlashMessage;
