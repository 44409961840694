var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { BaseApi, PathParam, QueryParam, HeaderParam, Body, Required, ApiMethod, getFilledUrl, getQueryParams, getHeaderParams, getBody, getApiMethodMetadata } from './common';
import axios from 'axios';
export var API;
(function (API) {
    /**
     * EventApi
     * @export
     */
    var EventApi = /** @class */ (function (_super) {
        __extends(EventApi, _super);
        function EventApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Find a related item by id for ticketTypes.
         * @param {string} id Event id
             * @param {string} fk Foreign key for ticketTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventPrototypeFindByIdTicketTypes = function (id, fk) {
            var url = getFilledUrl(this, 'eventPrototypeFindByIdTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'eventPrototypeFindByIdTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'eventPrototypeFindByIdTicketTypes', arguments);
            var body = getBody(this, 'eventPrototypeFindByIdTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'eventPrototypeFindByIdTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request ticket types of event
         * @param {number} id undefined
             * @param {string} [filter] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetManagerEvents = function (id, filter) {
            var url = getFilledUrl(this, 'eventGetManagerEvents', arguments);
            var queryParams = getQueryParams(this, 'eventGetManagerEvents', arguments);
            var headerParams = getHeaderParams(this, 'eventGetManagerEvents', arguments);
            var body = getBody(this, 'eventGetManagerEvents', arguments);
            var config = getApiMethodMetadata(this, 'eventGetManagerEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Creates a new instance in ticketTypes of this model.
         * @param {string} id Event id
             * @param {TicketType} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventPrototypeCreateTicketTypes = function (id, data) {
            var url = getFilledUrl(this, 'eventPrototypeCreateTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'eventPrototypeCreateTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'eventPrototypeCreateTicketTypes', arguments);
            var body = getBody(this, 'eventPrototypeCreateTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'eventPrototypeCreateTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get event information for doorman app.
         * @param {string} code Model code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetDoormanInfo = function (code) {
            var url = getFilledUrl(this, 'eventGetDoormanInfo', arguments);
            var queryParams = getQueryParams(this, 'eventGetDoormanInfo', arguments);
            var headerParams = getHeaderParams(this, 'eventGetDoormanInfo', arguments);
            var body = getBody(this, 'eventGetDoormanInfo', arguments);
            var config = getApiMethodMetadata(this, 'eventGetDoormanInfo', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Check if even exists or not by event code
         * @param {string} code Model code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetEventExists = function (code) {
            var url = getFilledUrl(this, 'eventGetEventExists', arguments);
            var queryParams = getQueryParams(this, 'eventGetEventExists', arguments);
            var headerParams = getHeaderParams(this, 'eventGetEventExists', arguments);
            var body = getBody(this, 'eventGetEventExists', arguments);
            var config = getApiMethodMetadata(this, 'eventGetEventExists', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update TicketType expect tmFieldMapping
         * @param {number} id undefined
             * @param {number} ticketTypeId undefined
             * @param {TicketTypeUpdateDto} ticketTypeData undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventUpdateTicketType = function (id, ticketTypeId, ticketTypeData) {
            var url = getFilledUrl(this, 'eventUpdateTicketType', arguments);
            var queryParams = getQueryParams(this, 'eventUpdateTicketType', arguments);
            var headerParams = getHeaderParams(this, 'eventUpdateTicketType', arguments);
            var body = getBody(this, 'eventUpdateTicketType', arguments);
            var config = getApiMethodMetadata(this, 'eventUpdateTicketType', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete specified TicketType record if no ticket belongs to it.
         * @param {number} id undefined
             * @param {number} ticketTypeId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventDeleteTicketType = function (id, ticketTypeId) {
            var url = getFilledUrl(this, 'eventDeleteTicketType', arguments);
            var queryParams = getQueryParams(this, 'eventDeleteTicketType', arguments);
            var headerParams = getHeaderParams(this, 'eventDeleteTicketType', arguments);
            var body = getBody(this, 'eventDeleteTicketType', arguments);
            var config = getApiMethodMetadata(this, 'eventDeleteTicketType', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetActivationCode = function (id) {
            var url = getFilledUrl(this, 'eventGetActivationCode', arguments);
            var queryParams = getQueryParams(this, 'eventGetActivationCode', arguments);
            var headerParams = getHeaderParams(this, 'eventGetActivationCode', arguments);
            var body = getBody(this, 'eventGetActivationCode', arguments);
            var config = getApiMethodMetadata(this, 'eventGetActivationCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Check if given password is a valid one for an Event
         * @param {CheckPasswordDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventCheckPassword = function (dto) {
            var url = getFilledUrl(this, 'eventCheckPassword', arguments);
            var queryParams = getQueryParams(this, 'eventCheckPassword', arguments);
            var headerParams = getHeaderParams(this, 'eventCheckPassword', arguments);
            var body = getBody(this, 'eventCheckPassword', arguments);
            var config = getApiMethodMetadata(this, 'eventCheckPassword', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Synchronize ticket list for an event defined by password
         * @param {DoormanSyncDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventSyncDoorman = function (dto) {
            var url = getFilledUrl(this, 'eventSyncDoorman', arguments);
            var queryParams = getQueryParams(this, 'eventSyncDoorman', arguments);
            var headerParams = getHeaderParams(this, 'eventSyncDoorman', arguments);
            var body = getBody(this, 'eventSyncDoorman', arguments);
            var config = getApiMethodMetadata(this, 'eventSyncDoorman', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get the ticket delivery stats of the event
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetTicketDeliveryStats = function (id) {
            var url = getFilledUrl(this, 'eventGetTicketDeliveryStats', arguments);
            var queryParams = getQueryParams(this, 'eventGetTicketDeliveryStats', arguments);
            var headerParams = getHeaderParams(this, 'eventGetTicketDeliveryStats', arguments);
            var body = getBody(this, 'eventGetTicketDeliveryStats', arguments);
            var config = getApiMethodMetadata(this, 'eventGetTicketDeliveryStats', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get the ticket transfer stats of the event
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetTicketTransferStats = function (id) {
            var url = getFilledUrl(this, 'eventGetTicketTransferStats', arguments);
            var queryParams = getQueryParams(this, 'eventGetTicketTransferStats', arguments);
            var headerParams = getHeaderParams(this, 'eventGetTicketTransferStats', arguments);
            var body = getBody(this, 'eventGetTicketTransferStats', arguments);
            var config = getApiMethodMetadata(this, 'eventGetTicketTransferStats', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get the userbase stats of the event
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetUserbaseStats = function (id) {
            var url = getFilledUrl(this, 'eventGetUserbaseStats', arguments);
            var queryParams = getQueryParams(this, 'eventGetUserbaseStats', arguments);
            var headerParams = getHeaderParams(this, 'eventGetUserbaseStats', arguments);
            var body = getBody(this, 'eventGetUserbaseStats', arguments);
            var config = getApiMethodMetadata(this, 'eventGetUserbaseStats', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get timeseries data for certain ticket statuses
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetTicketStatusTimeseriesStats = function (id) {
            var url = getFilledUrl(this, 'eventGetTicketStatusTimeseriesStats', arguments);
            var queryParams = getQueryParams(this, 'eventGetTicketStatusTimeseriesStats', arguments);
            var headerParams = getHeaderParams(this, 'eventGetTicketStatusTimeseriesStats', arguments);
            var body = getBody(this, 'eventGetTicketStatusTimeseriesStats', arguments);
            var config = getApiMethodMetadata(this, 'eventGetTicketStatusTimeseriesStats', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get the number of tickets which are not on account, thus notifiable
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetNotifiablesCount = function (id) {
            var url = getFilledUrl(this, 'eventGetNotifiablesCount', arguments);
            var queryParams = getQueryParams(this, 'eventGetNotifiablesCount', arguments);
            var headerParams = getHeaderParams(this, 'eventGetNotifiablesCount', arguments);
            var body = getBody(this, 'eventGetNotifiablesCount', arguments);
            var config = getApiMethodMetadata(this, 'eventGetNotifiablesCount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Resend notification smses about all undelivered but notified tickets to their users
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventBulkResendSmsInvitation = function (id) {
            var url = getFilledUrl(this, 'eventBulkResendSmsInvitation', arguments);
            var queryParams = getQueryParams(this, 'eventBulkResendSmsInvitation', arguments);
            var headerParams = getHeaderParams(this, 'eventBulkResendSmsInvitation', arguments);
            var body = getBody(this, 'eventBulkResendSmsInvitation', arguments);
            var config = getApiMethodMetadata(this, 'eventBulkResendSmsInvitation', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} code Model code
             * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        EventApi.prototype.eventGetByCode = function (code, filter) {
            var url = getFilledUrl(this, 'eventGetByCode', arguments);
            var queryParams = getQueryParams(this, 'eventGetByCode', arguments);
            var headerParams = getHeaderParams(this, 'eventGetByCode', arguments);
            var body = getBody(this, 'eventGetByCode', arguments);
            var config = getApiMethodMetadata(this, 'eventGetByCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/ticketTypes/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventPrototypeFindByIdTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/ticketTypes',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetManagerEvents", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Events/{id}/ticketTypes',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventPrototypeCreateTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{code}/doorman',
            }),
            __param(0, PathParam('code')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetDoormanInfo", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/code/{code}/exists',
            }),
            __param(0, PathParam('code')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetEventExists", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Events/{id}/ticketTypes/{ticketTypeId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketTypeId')),
            __param(2, Required('ticketTypeData')),
            __param(2, Body('ticketTypeData')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventUpdateTicketType", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Events/{id}/ticketTypes/{ticketTypeId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketTypeId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventDeleteTicketType", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/activationCode',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetActivationCode", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Events/doorman/check',
            }),
            __param(0, Required('dto')),
            __param(0, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventCheckPassword", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Events/doorman/sync',
            }),
            __param(0, Required('dto')),
            __param(0, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventSyncDoorman", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/ticketDeliveryStats',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetTicketDeliveryStats", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/ticketTransferStats',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetTicketTransferStats", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/ticketUserbaseStats',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetUserbaseStats", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/ticketStatusTimeseries',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetTicketStatusTimeseriesStats", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/{id}/notifiableTickets',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetNotifiablesCount", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Events/{id}/bulkResendSmsInvitation',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventBulkResendSmsInvitation", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Events/code/{code}',
            }),
            __param(0, PathParam('code')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], EventApi.prototype, "eventGetByCode", null);
        return EventApi;
    }(BaseApi));
    API.EventApi = EventApi;
    ;
    /**
     * TicketApi
     * @export
     */
    var TicketApi = /** @class */ (function (_super) {
        __extends(TicketApi, _super);
        function TicketApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Find a related item by id for transferRequests.
         * @param {string} id Ticket id
             * @param {string} fk Foreign key for transferRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeFindByIdTransferRequests = function (id, fk) {
            var url = getFilledUrl(this, 'ticketPrototypeFindByIdTransferRequests', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeFindByIdTransferRequests', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeFindByIdTransferRequests', arguments);
            var body = getBody(this, 'ticketPrototypeFindByIdTransferRequests', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeFindByIdTransferRequests', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete a related item by id for transferRequests.
         * @param {string} id Ticket id
             * @param {string} fk Foreign key for transferRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeDestroyByIdTransferRequests = function (id, fk) {
            var url = getFilledUrl(this, 'ticketPrototypeDestroyByIdTransferRequests', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeDestroyByIdTransferRequests', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeDestroyByIdTransferRequests', arguments);
            var body = getBody(this, 'ticketPrototypeDestroyByIdTransferRequests', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeDestroyByIdTransferRequests', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update a related item by id for transferRequests.
         * @param {string} id Ticket id
             * @param {string} fk Foreign key for transferRequests
             * @param {TransferRequest} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeUpdateByIdTransferRequests = function (id, fk, data) {
            var url = getFilledUrl(this, 'ticketPrototypeUpdateByIdTransferRequests', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeUpdateByIdTransferRequests', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeUpdateByIdTransferRequests', arguments);
            var body = getBody(this, 'ticketPrototypeUpdateByIdTransferRequests', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeUpdateByIdTransferRequests', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Fetches belongsTo relation tmEntryCode.
         * @param {string} id Ticket id
             * @param {boolean} [refresh] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeGetTmEntryCode = function (id, refresh) {
            var url = getFilledUrl(this, 'ticketPrototypeGetTmEntryCode', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeGetTmEntryCode', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeGetTmEntryCode', arguments);
            var body = getBody(this, 'ticketPrototypeGetTmEntryCode', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeGetTmEntryCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Queries transferRequests of Ticket.
         * @param {string} id Ticket id
             * @param {string} [filter] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeGetTransferRequests = function (id, filter) {
            var url = getFilledUrl(this, 'ticketPrototypeGetTransferRequests', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeGetTransferRequests', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeGetTransferRequests', arguments);
            var body = getBody(this, 'ticketPrototypeGetTransferRequests', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeGetTransferRequests', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Creates a new instance in transferRequests of this model.
         * @param {string} id Ticket id
             * @param {TransferRequest} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeCreateTransferRequests = function (id, data) {
            var url = getFilledUrl(this, 'ticketPrototypeCreateTransferRequests', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeCreateTransferRequests', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeCreateTransferRequests', arguments);
            var body = getBody(this, 'ticketPrototypeCreateTransferRequests', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeCreateTransferRequests', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Deletes all transferRequests of this model.
         * @param {string} id Ticket id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeDeleteTransferRequests = function (id) {
            var url = getFilledUrl(this, 'ticketPrototypeDeleteTransferRequests', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeDeleteTransferRequests', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeDeleteTransferRequests', arguments);
            var body = getBody(this, 'ticketPrototypeDeleteTransferRequests', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeDeleteTransferRequests', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Counts transferRequests of Ticket.
         * @param {string} id Ticket id
             * @param {string} [where] Criteria to match model instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketPrototypeCountTransferRequests = function (id, where) {
            var url = getFilledUrl(this, 'ticketPrototypeCountTransferRequests', arguments);
            var queryParams = getQueryParams(this, 'ticketPrototypeCountTransferRequests', arguments);
            var headerParams = getHeaderParams(this, 'ticketPrototypeCountTransferRequests', arguments);
            var body = getBody(this, 'ticketPrototypeCountTransferRequests', arguments);
            var config = getApiMethodMetadata(this, 'ticketPrototypeCountTransferRequests', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketDeleteTicketById = function (id) {
            var url = getFilledUrl(this, 'ticketDeleteTicketById', arguments);
            var queryParams = getQueryParams(this, 'ticketDeleteTicketById', arguments);
            var headerParams = getHeaderParams(this, 'ticketDeleteTicketById', arguments);
            var body = getBody(this, 'ticketDeleteTicketById', arguments);
            var config = getApiMethodMetadata(this, 'ticketDeleteTicketById', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} privateKey undefined
             * @param {number} eventId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketCheck = function (privateKey, eventId) {
            var url = getFilledUrl(this, 'ticketCheck', arguments);
            var queryParams = getQueryParams(this, 'ticketCheck', arguments);
            var headerParams = getHeaderParams(this, 'ticketCheck', arguments);
            var body = getBody(this, 'ticketCheck', arguments);
            var config = getApiMethodMetadata(this, 'ticketCheck', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} phoneNumber undefined
             * @param {string} eventCode undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketCheckByPhoneNumber = function (phoneNumber, eventCode) {
            var url = getFilledUrl(this, 'ticketCheckByPhoneNumber', arguments);
            var queryParams = getQueryParams(this, 'ticketCheckByPhoneNumber', arguments);
            var headerParams = getHeaderParams(this, 'ticketCheckByPhoneNumber', arguments);
            var body = getBody(this, 'ticketCheckByPhoneNumber', arguments);
            var config = getApiMethodMetadata(this, 'ticketCheckByPhoneNumber', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} privateKey undefined
             * @param {ConsumeDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketConsume = function (privateKey, dto) {
            var url = getFilledUrl(this, 'ticketConsume', arguments);
            var queryParams = getQueryParams(this, 'ticketConsume', arguments);
            var headerParams = getHeaderParams(this, 'ticketConsume', arguments);
            var body = getBody(this, 'ticketConsume', arguments);
            var config = getApiMethodMetadata(this, 'ticketConsume', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} eventCode undefined
             * @param {Array<string>} publicKeys undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketBulkConsume = function (eventCode, publicKeys) {
            var url = getFilledUrl(this, 'ticketBulkConsume', arguments);
            var queryParams = getQueryParams(this, 'ticketBulkConsume', arguments);
            var headerParams = getHeaderParams(this, 'ticketBulkConsume', arguments);
            var body = getBody(this, 'ticketBulkConsume', arguments);
            var config = getApiMethodMetadata(this, 'ticketBulkConsume', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketSelfConsume = function (id) {
            var url = getFilledUrl(this, 'ticketSelfConsume', arguments);
            var queryParams = getQueryParams(this, 'ticketSelfConsume', arguments);
            var headerParams = getHeaderParams(this, 'ticketSelfConsume', arguments);
            var body = getBody(this, 'ticketSelfConsume', arguments);
            var config = getApiMethodMetadata(this, 'ticketSelfConsume', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketApi.prototype.ticketLogScreenshot = function (id) {
            var url = getFilledUrl(this, 'ticketLogScreenshot', arguments);
            var queryParams = getQueryParams(this, 'ticketLogScreenshot', arguments);
            var headerParams = getHeaderParams(this, 'ticketLogScreenshot', arguments);
            var body = getBody(this, 'ticketLogScreenshot', arguments);
            var config = getApiMethodMetadata(this, 'ticketLogScreenshot', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Tickets/{id}/transferRequests/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeFindByIdTransferRequests", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Tickets/{id}/transferRequests/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeDestroyByIdTransferRequests", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Tickets/{id}/transferRequests/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __param(2, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, Object]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeUpdateByIdTransferRequests", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Tickets/{id}/tmEntryCode',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('refresh')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Boolean]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeGetTmEntryCode", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Tickets/{id}/transferRequests',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeGetTransferRequests", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Tickets/{id}/transferRequests',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeCreateTransferRequests", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Tickets/{id}/transferRequests',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeDeleteTransferRequests", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Tickets/{id}/transferRequests/count',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('where')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketPrototypeCountTransferRequests", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Tickets/{id}',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketDeleteTicketById", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Tickets/{privateKey}/check',
            }),
            __param(0, PathParam('privateKey')),
            __param(1, Required('eventId')),
            __param(1, QueryParam('eventId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Number]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketCheck", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Tickets/checkByPhoneNumber',
            }),
            __param(0, Required('phoneNumber')),
            __param(0, QueryParam('phoneNumber')),
            __param(1, Required('eventCode')),
            __param(1, QueryParam('eventCode')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketCheckByPhoneNumber", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Tickets/{privateKey}/consume',
            }),
            __param(0, PathParam('privateKey')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketConsume", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Tickets/bulkConsume',
            }),
            __param(0, Required('eventCode')),
            __param(0, QueryParam('eventCode')),
            __param(1, Required('publicKeys')),
            __param(1, Body('publicKeys')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Array]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketBulkConsume", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Tickets/{id}/selfConsume',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketSelfConsume", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Tickets/{id}/logScreenshot',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], TicketApi.prototype, "ticketLogScreenshot", null);
        return TicketApi;
    }(BaseApi));
    API.TicketApi = TicketApi;
    ;
    /**
     * TicketTypeApi
     * @export
     */
    var TicketTypeApi = /** @class */ (function (_super) {
        __extends(TicketTypeApi, _super);
        function TicketTypeApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Fetches belongsTo relation section.
         * @param {string} id TicketType id
             * @param {boolean} [refresh] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketTypeApi.prototype.ticketTypePrototypeGetSection = function (id, refresh) {
            var url = getFilledUrl(this, 'ticketTypePrototypeGetSection', arguments);
            var queryParams = getQueryParams(this, 'ticketTypePrototypeGetSection', arguments);
            var headerParams = getHeaderParams(this, 'ticketTypePrototypeGetSection', arguments);
            var body = getBody(this, 'ticketTypePrototypeGetSection', arguments);
            var config = getApiMethodMetadata(this, 'ticketTypePrototypeGetSection', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get Ticket type information by set of code.
         * @param {string} ticketTypeCodes Model codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketTypeApi.prototype.ticketTypeGetTicketTypesInfo = function (ticketTypeCodes) {
            var url = getFilledUrl(this, 'ticketTypeGetTicketTypesInfo', arguments);
            var queryParams = getQueryParams(this, 'ticketTypeGetTicketTypesInfo', arguments);
            var headerParams = getHeaderParams(this, 'ticketTypeGetTicketTypesInfo', arguments);
            var body = getBody(this, 'ticketTypeGetTicketTypesInfo', arguments);
            var config = getApiMethodMetadata(this, 'ticketTypeGetTicketTypesInfo', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} code Model code
             * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TicketTypeApi.prototype.ticketTypeGetByCode = function (code, filter) {
            var url = getFilledUrl(this, 'ticketTypeGetByCode', arguments);
            var queryParams = getQueryParams(this, 'ticketTypeGetByCode', arguments);
            var headerParams = getHeaderParams(this, 'ticketTypeGetByCode', arguments);
            var body = getBody(this, 'ticketTypeGetByCode', arguments);
            var config = getApiMethodMetadata(this, 'ticketTypeGetByCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/TicketTypes/{id}/section',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('refresh')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Boolean]),
            __metadata("design:returntype", Promise)
        ], TicketTypeApi.prototype, "ticketTypePrototypeGetSection", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/TicketTypes',
            }),
            __param(0, Required('ticketTypeCodes')),
            __param(0, QueryParam('ticketTypeCodes')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], TicketTypeApi.prototype, "ticketTypeGetTicketTypesInfo", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/TicketTypes/code/{code}',
            }),
            __param(0, PathParam('code')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], TicketTypeApi.prototype, "ticketTypeGetByCode", null);
        return TicketTypeApi;
    }(BaseApi));
    API.TicketTypeApi = TicketTypeApi;
    ;
    /**
     * TixFileApi
     * @export
     */
    var TixFileApi = /** @class */ (function (_super) {
        __extends(TixFileApi, _super);
        function TixFileApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Fetches belongsTo relation imageType.
         * @param {string} id TixFile id
             * @param {boolean} [refresh] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TixFileApi.prototype.tixFilePrototypeGetImageType = function (id, refresh) {
            var url = getFilledUrl(this, 'tixFilePrototypeGetImageType', arguments);
            var queryParams = getQueryParams(this, 'tixFilePrototypeGetImageType', arguments);
            var headerParams = getHeaderParams(this, 'tixFilePrototypeGetImageType', arguments);
            var body = getBody(this, 'tixFilePrototypeGetImageType', arguments);
            var config = getApiMethodMetadata(this, 'tixFilePrototypeGetImageType', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find all instances of the model matched by filter from the data source.
         * @param {string} [filter] Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TixFileApi.prototype.tixFileFind = function (filter) {
            var url = getFilledUrl(this, 'tixFileFind', arguments);
            var queryParams = getQueryParams(this, 'tixFileFind', arguments);
            var headerParams = getHeaderParams(this, 'tixFileFind', arguments);
            var body = getBody(this, 'tixFileFind', arguments);
            var config = getApiMethodMetadata(this, 'tixFileFind', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find a model instance by id from the data source.
    Returns with an exactly one-element array because of a strongloop bug.
         * @param {string} id Model id
             * @param {string} [filter] Filter defining fields and include
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        TixFileApi.prototype.tixFileCustomFindById = function (id, filter) {
            var url = getFilledUrl(this, 'tixFileCustomFindById', arguments);
            var queryParams = getQueryParams(this, 'tixFileCustomFindById', arguments);
            var headerParams = getHeaderParams(this, 'tixFileCustomFindById', arguments);
            var body = getBody(this, 'tixFileCustomFindById', arguments);
            var config = getApiMethodMetadata(this, 'tixFileCustomFindById', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/TixFiles/{id}/imageType',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('refresh')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Boolean]),
            __metadata("design:returntype", Promise)
        ], TixFileApi.prototype, "tixFilePrototypeGetImageType", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/TixFiles',
            }),
            __param(0, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], TixFileApi.prototype, "tixFileFind", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/TixFiles/{id}',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], TixFileApi.prototype, "tixFileCustomFindById", null);
        return TixFileApi;
    }(BaseApi));
    API.TixFileApi = TixFileApi;
    ;
    /**
     * ContainerApi
     * @export
     */
    var ContainerApi = /** @class */ (function (_super) {
        __extends(ContainerApi, _super);
        function ContainerApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary undefined
        
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerGetContainers = function () {
            var url = getFilledUrl(this, 'containerGetContainers', arguments);
            var queryParams = getQueryParams(this, 'containerGetContainers', arguments);
            var headerParams = getHeaderParams(this, 'containerGetContainers', arguments);
            var body = getBody(this, 'containerGetContainers', arguments);
            var config = getApiMethodMetadata(this, 'containerGetContainers', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {any} [options] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerCreateContainer = function (options) {
            var url = getFilledUrl(this, 'containerCreateContainer', arguments);
            var queryParams = getQueryParams(this, 'containerCreateContainer', arguments);
            var headerParams = getHeaderParams(this, 'containerCreateContainer', arguments);
            var body = getBody(this, 'containerCreateContainer', arguments);
            var config = getApiMethodMetadata(this, 'containerCreateContainer', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} container undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerDestroyContainer = function (container) {
            var url = getFilledUrl(this, 'containerDestroyContainer', arguments);
            var queryParams = getQueryParams(this, 'containerDestroyContainer', arguments);
            var headerParams = getHeaderParams(this, 'containerDestroyContainer', arguments);
            var body = getBody(this, 'containerDestroyContainer', arguments);
            var config = getApiMethodMetadata(this, 'containerDestroyContainer', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} container undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerGetContainer = function (container) {
            var url = getFilledUrl(this, 'containerGetContainer', arguments);
            var queryParams = getQueryParams(this, 'containerGetContainer', arguments);
            var headerParams = getHeaderParams(this, 'containerGetContainer', arguments);
            var body = getBody(this, 'containerGetContainer', arguments);
            var config = getApiMethodMetadata(this, 'containerGetContainer', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} container undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerGetFiles = function (container) {
            var url = getFilledUrl(this, 'containerGetFiles', arguments);
            var queryParams = getQueryParams(this, 'containerGetFiles', arguments);
            var headerParams = getHeaderParams(this, 'containerGetFiles', arguments);
            var body = getBody(this, 'containerGetFiles', arguments);
            var config = getApiMethodMetadata(this, 'containerGetFiles', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} container undefined
             * @param {string} file undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerGetFile = function (container, file) {
            var url = getFilledUrl(this, 'containerGetFile', arguments);
            var queryParams = getQueryParams(this, 'containerGetFile', arguments);
            var headerParams = getHeaderParams(this, 'containerGetFile', arguments);
            var body = getBody(this, 'containerGetFile', arguments);
            var config = getApiMethodMetadata(this, 'containerGetFile', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} container undefined
             * @param {string} file undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerRemoveFile = function (container, file) {
            var url = getFilledUrl(this, 'containerRemoveFile', arguments);
            var queryParams = getQueryParams(this, 'containerRemoveFile', arguments);
            var headerParams = getHeaderParams(this, 'containerRemoveFile', arguments);
            var body = getBody(this, 'containerRemoveFile', arguments);
            var config = getApiMethodMetadata(this, 'containerRemoveFile', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} container undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerUpload = function (container) {
            var url = getFilledUrl(this, 'containerUpload', arguments);
            var queryParams = getQueryParams(this, 'containerUpload', arguments);
            var headerParams = getHeaderParams(this, 'containerUpload', arguments);
            var body = getBody(this, 'containerUpload', arguments);
            var config = getApiMethodMetadata(this, 'containerUpload', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} container undefined
             * @param {string} file undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ContainerApi.prototype.containerDownload = function (container, file) {
            var url = getFilledUrl(this, 'containerDownload', arguments);
            var queryParams = getQueryParams(this, 'containerDownload', arguments);
            var headerParams = getHeaderParams(this, 'containerDownload', arguments);
            var body = getBody(this, 'containerDownload', arguments);
            var config = getApiMethodMetadata(this, 'containerDownload', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Containers',
            }),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", []),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerGetContainers", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Containers',
            }),
            __param(0, Body('options')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerCreateContainer", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Containers/{container}',
            }),
            __param(0, PathParam('container')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerDestroyContainer", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Containers/{container}',
            }),
            __param(0, PathParam('container')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerGetContainer", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Containers/{container}/files',
            }),
            __param(0, PathParam('container')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerGetFiles", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Containers/{container}/files/{file}',
            }),
            __param(0, PathParam('container')),
            __param(1, PathParam('file')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerGetFile", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Containers/{container}/files/{file}',
            }),
            __param(0, PathParam('container')),
            __param(1, PathParam('file')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerRemoveFile", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Containers/{container}/upload',
            }),
            __param(0, PathParam('container')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerUpload", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Containers/{container}/download/{file}',
            }),
            __param(0, PathParam('container')),
            __param(1, PathParam('file')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], ContainerApi.prototype, "containerDownload", null);
        return ContainerApi;
    }(BaseApi));
    API.ContainerApi = ContainerApi;
    ;
    /**
     * ManagerApi
     * @export
     */
    var ManagerApi = /** @class */ (function (_super) {
        __extends(ManagerApi, _super);
        function ManagerApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Patch attributes for a model instance and persist it into the data source.
         * @param {string} id Manager id
             * @param {Manager} [data] An object of model property name/value pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ManagerApi.prototype.managerPrototypePatchAttributesPutManagersId = function (id, data) {
            var url = getFilledUrl(this, 'managerPrototypePatchAttributesPutManagersId', arguments);
            var queryParams = getQueryParams(this, 'managerPrototypePatchAttributesPutManagersId', arguments);
            var headerParams = getHeaderParams(this, 'managerPrototypePatchAttributesPutManagersId', arguments);
            var body = getBody(this, 'managerPrototypePatchAttributesPutManagersId', arguments);
            var config = getApiMethodMetadata(this, 'managerPrototypePatchAttributesPutManagersId', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Patch attributes for a model instance and persist it into the data source.
         * @param {string} id Manager id
             * @param {Manager} [data] An object of model property name/value pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ManagerApi.prototype.managerPrototypePatchAttributesPatchManagersId = function (id, data) {
            var url = getFilledUrl(this, 'managerPrototypePatchAttributesPatchManagersId', arguments);
            var queryParams = getQueryParams(this, 'managerPrototypePatchAttributesPatchManagersId', arguments);
            var headerParams = getHeaderParams(this, 'managerPrototypePatchAttributesPatchManagersId', arguments);
            var body = getBody(this, 'managerPrototypePatchAttributesPatchManagersId', arguments);
            var config = getApiMethodMetadata(this, 'managerPrototypePatchAttributesPatchManagersId', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request manager data
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ManagerApi.prototype.managerGetManager = function (id) {
            var url = getFilledUrl(this, 'managerGetManager', arguments);
            var queryParams = getQueryParams(this, 'managerGetManager', arguments);
            var headerParams = getHeaderParams(this, 'managerGetManager', arguments);
            var body = getBody(this, 'managerGetManager', arguments);
            var config = getApiMethodMetadata(this, 'managerGetManager', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Manager login
         * @param {ManagerLoginDto} [data]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ManagerApi.prototype.managerLogin = function (data) {
            var url = getFilledUrl(this, 'managerLogin', arguments);
            var queryParams = getQueryParams(this, 'managerLogin', arguments);
            var headerParams = getHeaderParams(this, 'managerLogin', arguments);
            var body = getBody(this, 'managerLogin', arguments);
            var config = getApiMethodMetadata(this, 'managerLogin', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Modify user password
         * @param {ChangePwdDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ManagerApi.prototype.managerChangePassword = function (dto) {
            var url = getFilledUrl(this, 'managerChangePassword', arguments);
            var queryParams = getQueryParams(this, 'managerChangePassword', arguments);
            var headerParams = getHeaderParams(this, 'managerChangePassword', arguments);
            var body = getBody(this, 'managerChangePassword', arguments);
            var config = getApiMethodMetadata(this, 'managerChangePassword', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Test name comparison
         * @param {string} [nameA] undefined
             * @param {string} [nameB] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ManagerApi.prototype.managerTestNameComparison = function (nameA, nameB) {
            var url = getFilledUrl(this, 'managerTestNameComparison', arguments);
            var queryParams = getQueryParams(this, 'managerTestNameComparison', arguments);
            var headerParams = getHeaderParams(this, 'managerTestNameComparison', arguments);
            var body = getBody(this, 'managerTestNameComparison', arguments);
            var config = getApiMethodMetadata(this, 'managerTestNameComparison', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request account data of manager
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ManagerApi.prototype.managerGetAccount = function (id) {
            var url = getFilledUrl(this, 'managerGetAccount', arguments);
            var queryParams = getQueryParams(this, 'managerGetAccount', arguments);
            var headerParams = getHeaderParams(this, 'managerGetAccount', arguments);
            var body = getBody(this, 'managerGetAccount', arguments);
            var config = getApiMethodMetadata(this, 'managerGetAccount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Managers/{id}',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], ManagerApi.prototype, "managerPrototypePatchAttributesPutManagersId", null);
        __decorate([
            ApiMethod({
                method: 'patch',
                url: '/Managers/{id}',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], ManagerApi.prototype, "managerPrototypePatchAttributesPatchManagersId", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Managers/{id}',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], ManagerApi.prototype, "managerGetManager", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Managers/login',
            }),
            __param(0, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], ManagerApi.prototype, "managerLogin", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Managers/changePassword',
            }),
            __param(0, Required('dto')),
            __param(0, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], ManagerApi.prototype, "managerChangePassword", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Managers/testNameComparison',
            }),
            __param(0, QueryParam('nameA')),
            __param(1, QueryParam('nameB')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], ManagerApi.prototype, "managerTestNameComparison", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Managers/{id}/account',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], ManagerApi.prototype, "managerGetAccount", null);
        return ManagerApi;
    }(BaseApi));
    API.ManagerApi = ManagerApi;
    ;
    /**
     * AgentApi
     * @export
     */
    var AgentApi = /** @class */ (function (_super) {
        __extends(AgentApi, _super);
        function AgentApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Agent verification request
         * @param {AgentSendVerificationCodeDto} [dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentSendVerificationCode = function (dto) {
            var url = getFilledUrl(this, 'agentSendVerificationCode', arguments);
            var queryParams = getQueryParams(this, 'agentSendVerificationCode', arguments);
            var headerParams = getHeaderParams(this, 'agentSendVerificationCode', arguments);
            var body = getBody(this, 'agentSendVerificationCode', arguments);
            var config = getApiMethodMetadata(this, 'agentSendVerificationCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Agent login
         * @param {AgentLoginDto} [data]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentLogin = function (data) {
            var url = getFilledUrl(this, 'agentLogin', arguments);
            var queryParams = getQueryParams(this, 'agentLogin', arguments);
            var headerParams = getHeaderParams(this, 'agentLogin', arguments);
            var body = getBody(this, 'agentLogin', arguments);
            var config = getApiMethodMetadata(this, 'agentLogin', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Returns the filtered agents ticket (assigned or unassigned)
         * @param {number} id undefined
             * @param {AgentGetTicketDto} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentGetAgentTickets = function (id, data) {
            var url = getFilledUrl(this, 'agentGetAgentTickets', arguments);
            var queryParams = getQueryParams(this, 'agentGetAgentTickets', arguments);
            var headerParams = getHeaderParams(this, 'agentGetAgentTickets', arguments);
            var body = getBody(this, 'agentGetAgentTickets', arguments);
            var config = getApiMethodMetadata(this, 'agentGetAgentTickets', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request events of agent by account
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentGetAgentEventsByAccount = function (id, isUpcoming) {
            var url = getFilledUrl(this, 'agentGetAgentEventsByAccount', arguments);
            var queryParams = getQueryParams(this, 'agentGetAgentEventsByAccount', arguments);
            var headerParams = getHeaderParams(this, 'agentGetAgentEventsByAccount', arguments);
            var body = getBody(this, 'agentGetAgentEventsByAccount', arguments);
            var config = getApiMethodMetadata(this, 'agentGetAgentEventsByAccount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request an event of agent
         * @param {number} id undefined
             * @param {number} eventId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentGetAgentEvent = function (id, eventId) {
            var url = getFilledUrl(this, 'agentGetAgentEvent', arguments);
            var queryParams = getQueryParams(this, 'agentGetAgentEvent', arguments);
            var headerParams = getHeaderParams(this, 'agentGetAgentEvent', arguments);
            var body = getBody(this, 'agentGetAgentEvent', arguments);
            var config = getApiMethodMetadata(this, 'agentGetAgentEvent', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request filters for ticket lists
         * @param {number} id undefined
             * @param {AgentTicketFiltersDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentGetTicketFilters = function (id, dto) {
            var url = getFilledUrl(this, 'agentGetTicketFilters', arguments);
            var queryParams = getQueryParams(this, 'agentGetTicketFilters', arguments);
            var headerParams = getHeaderParams(this, 'agentGetTicketFilters', arguments);
            var body = getBody(this, 'agentGetTicketFilters', arguments);
            var config = getApiMethodMetadata(this, 'agentGetTicketFilters', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update specified tickets' agent.
         * @param {number} id undefined
             * @param {number} accountId undefined
             * @param {SendTicketsToAgentDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentSendTicketsToAgent = function (id, accountId, dto) {
            var url = getFilledUrl(this, 'agentSendTicketsToAgent', arguments);
            var queryParams = getQueryParams(this, 'agentSendTicketsToAgent', arguments);
            var headerParams = getHeaderParams(this, 'agentSendTicketsToAgent', arguments);
            var body = getBody(this, 'agentSendTicketsToAgent', arguments);
            var config = getApiMethodMetadata(this, 'agentSendTicketsToAgent', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Assign selected tickets to a client
         * @param {number} id undefined
             * @param {AssignTicketsDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentAssignTickets = function (id, dto) {
            var url = getFilledUrl(this, 'agentAssignTickets', arguments);
            var queryParams = getQueryParams(this, 'agentAssignTickets', arguments);
            var headerParams = getHeaderParams(this, 'agentAssignTickets', arguments);
            var body = getBody(this, 'agentAssignTickets', arguments);
            var config = getApiMethodMetadata(this, 'agentAssignTickets', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Recall selected ticket to agent pool of tickets
         * @param {number} id undefined
             * @param {RecallTicketDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentRecallTicket = function (id, dto) {
            var url = getFilledUrl(this, 'agentRecallTicket', arguments);
            var queryParams = getQueryParams(this, 'agentRecallTicket', arguments);
            var headerParams = getHeaderParams(this, 'agentRecallTicket', arguments);
            var body = getBody(this, 'agentRecallTicket', arguments);
            var config = getApiMethodMetadata(this, 'agentRecallTicket', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find user by phone number and account
         * @param {number} id undefined
             * @param {number} accountId undefined
             * @param {string} phoneNumber Phone number of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentFindUserByPhoneNumber = function (id, accountId, phoneNumber) {
            var url = getFilledUrl(this, 'agentFindUserByPhoneNumber', arguments);
            var queryParams = getQueryParams(this, 'agentFindUserByPhoneNumber', arguments);
            var headerParams = getHeaderParams(this, 'agentFindUserByPhoneNumber', arguments);
            var body = getBody(this, 'agentFindUserByPhoneNumber', arguments);
            var config = getApiMethodMetadata(this, 'agentFindUserByPhoneNumber', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find agent by phone number
         * @param {number} id undefined
         * @param {string} phoneNumber Phone number of agent
         * @param {*} [options] Override http request option
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentFindAgentByPhoneNumber = function (id, phoneNumber) {
            var url = getFilledUrl(this, 'agentFindAgentByPhoneNumber', arguments);
            var queryParams = getQueryParams(this, 'agentFindAgentByPhoneNumber', arguments);
            var headerParams = getHeaderParams(this, 'agentFindAgentByPhoneNumber', arguments);
            var body = getBody(this, 'agentFindAgentByPhoneNumber', arguments);
            var config = getApiMethodMetadata(this, 'agentFindAgentByPhoneNumber', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Transfer ticket to agent
         * @param {number} id undefined
         * @param {TransferTicketsToAgentDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentTransferTicketToAgent = function (id, dto) {
            var url = getFilledUrl(this, 'agentTransferTicketToAgent', arguments);
            var queryParams = getQueryParams(this, 'agentTransferTicketToAgent', arguments);
            var headerParams = getHeaderParams(this, 'agentTransferTicketToAgent', arguments);
            var body = getBody(this, 'agentTransferTicketToAgent', arguments);
            var config = getApiMethodMetadata(this, 'agentTransferTicketToAgent', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request logs and ticket info for agent
         * @param {number} id undefined
             * @param {number} ticketId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AgentApi.prototype.agentGetTicketWithJourney = function (id, ticketId) {
            var url = getFilledUrl(this, 'agentGetTicketWithJourney', arguments);
            var queryParams = getQueryParams(this, 'agentGetTicketWithJourney', arguments);
            var headerParams = getHeaderParams(this, 'agentGetTicketWithJourney', arguments);
            var body = getBody(this, 'agentGetTicketWithJourney', arguments);
            var config = getApiMethodMetadata(this, 'agentGetTicketWithJourney', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Agents/verify',
            }),
            __param(0, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentSendVerificationCode", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Agents/login',
            }),
            __param(0, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentLogin", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Agents/{id}/getTickets',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentGetAgentTickets", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Agents/{id}/getEventsByAccount',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('isUpcoming')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Boolean]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentGetAgentEventsByAccount", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Agents/{id}/getEvent/{eventId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('eventId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentGetAgentEvent", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Agents/{id}/getTicketFilters',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentGetTicketFilters", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Agents/{id}/sendTicketsToAgent/{accountId}',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('accountId')),
            __param(1, QueryParam('accountId')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentSendTicketsToAgent", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Agents/{id}/assignTickets',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentAssignTickets", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Agents/{id}/recallTicket',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentRecallTicket", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Agents/{id}/findUserByPhoneNumber/{accountId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('accountId')),
            __param(2, Required('phoneNumber')),
            __param(2, QueryParam('phoneNumber')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, String]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentFindUserByPhoneNumber", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Agents/{id}/findAgentByPhoneNumber',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('phoneNumber')),
            __param(1, QueryParam('phoneNumber')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentFindAgentByPhoneNumber", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Agents/{id}/transferTicketsToAgent',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentTransferTicketToAgent", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Agents/{id}/ticketWithJourney/{ticketId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AgentApi.prototype, "agentGetTicketWithJourney", null);
        return AgentApi;
    }(BaseApi));
    API.AgentApi = AgentApi;
    ;
    /**
     * ConfigApi
     * @export
     */
    var ConfigApi = /** @class */ (function (_super) {
        __extends(ConfigApi, _super);
        function ConfigApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
        * @summary Request configuration for the application
        * @param {string} type undefined
        * @throws {RequiredError}
        */
        ConfigApi.prototype.getAppConfig = function (type) {
            var url = getFilledUrl(this, "getAppConfig", arguments);
            var queryParams = getQueryParams(this, "getAppConfig", arguments);
            var headerParams = getHeaderParams(this, "getAppConfig", arguments);
            var config = getApiMethodMetadata(this, "getAppConfig", arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                params: queryParams,
                url: url,
                method: config.method,
            }).then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        __decorate([
            ApiMethod({
                method: "get",
                url: "/Config/{type}",
            }),
            __param(0, PathParam("type")),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], ConfigApi.prototype, "getAppConfig", null);
        return ConfigApi;
    }(BaseApi));
    API.ConfigApi = ConfigApi;
    /**
     * VenueApi
     * @export
     */
    var VenueApi = /** @class */ (function (_super) {
        __extends(VenueApi, _super);
        function VenueApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary undefined
         * @param {string} code Model code
             * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        VenueApi.prototype.venueGetByCode = function (code, filter) {
            var url = getFilledUrl(this, 'venueGetByCode', arguments);
            var queryParams = getQueryParams(this, 'venueGetByCode', arguments);
            var headerParams = getHeaderParams(this, 'venueGetByCode', arguments);
            var body = getBody(this, 'venueGetByCode', arguments);
            var config = getApiMethodMetadata(this, 'venueGetByCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Venues/code/{code}',
            }),
            __param(0, PathParam('code')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], VenueApi.prototype, "venueGetByCode", null);
        return VenueApi;
    }(BaseApi));
    API.VenueApi = VenueApi;
    ;
    /**
     * SectionApi
     * @export
     */
    var SectionApi = /** @class */ (function (_super) {
        __extends(SectionApi, _super);
        function SectionApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Fetches belongsTo relation seatmapImage.
         * @param {string} id Section id
             * @param {boolean} [refresh] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeGetSeatmapImage = function (id, refresh) {
            var url = getFilledUrl(this, 'sectionPrototypeGetSeatmapImage', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeGetSeatmapImage', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeGetSeatmapImage', arguments);
            var body = getBody(this, 'sectionPrototypeGetSeatmapImage', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeGetSeatmapImage', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find a related item by id for ticketTypes.
         * @param {string} id Section id
             * @param {string} fk Foreign key for ticketTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeFindByIdTicketTypes = function (id, fk) {
            var url = getFilledUrl(this, 'sectionPrototypeFindByIdTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeFindByIdTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeFindByIdTicketTypes', arguments);
            var body = getBody(this, 'sectionPrototypeFindByIdTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeFindByIdTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete a related item by id for ticketTypes.
         * @param {string} id Section id
             * @param {string} fk Foreign key for ticketTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeDestroyByIdTicketTypes = function (id, fk) {
            var url = getFilledUrl(this, 'sectionPrototypeDestroyByIdTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeDestroyByIdTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeDestroyByIdTicketTypes', arguments);
            var body = getBody(this, 'sectionPrototypeDestroyByIdTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeDestroyByIdTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update a related item by id for ticketTypes.
         * @param {string} id Section id
             * @param {string} fk Foreign key for ticketTypes
             * @param {TicketType} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeUpdateByIdTicketTypes = function (id, fk, data) {
            var url = getFilledUrl(this, 'sectionPrototypeUpdateByIdTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeUpdateByIdTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeUpdateByIdTicketTypes', arguments);
            var body = getBody(this, 'sectionPrototypeUpdateByIdTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeUpdateByIdTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Queries ticketTypes of Section.
         * @param {string} id Section id
             * @param {string} [filter] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeGetTicketTypes = function (id, filter) {
            var url = getFilledUrl(this, 'sectionPrototypeGetTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeGetTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeGetTicketTypes', arguments);
            var body = getBody(this, 'sectionPrototypeGetTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeGetTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Creates a new instance in ticketTypes of this model.
         * @param {string} id Section id
             * @param {TicketType} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeCreateTicketTypes = function (id, data) {
            var url = getFilledUrl(this, 'sectionPrototypeCreateTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeCreateTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeCreateTicketTypes', arguments);
            var body = getBody(this, 'sectionPrototypeCreateTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeCreateTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Deletes all ticketTypes of this model.
         * @param {string} id Section id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeDeleteTicketTypes = function (id) {
            var url = getFilledUrl(this, 'sectionPrototypeDeleteTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeDeleteTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeDeleteTicketTypes', arguments);
            var body = getBody(this, 'sectionPrototypeDeleteTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeDeleteTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Counts ticketTypes of Section.
         * @param {string} id Section id
             * @param {string} [where] Criteria to match model instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        SectionApi.prototype.sectionPrototypeCountTicketTypes = function (id, where) {
            var url = getFilledUrl(this, 'sectionPrototypeCountTicketTypes', arguments);
            var queryParams = getQueryParams(this, 'sectionPrototypeCountTicketTypes', arguments);
            var headerParams = getHeaderParams(this, 'sectionPrototypeCountTicketTypes', arguments);
            var body = getBody(this, 'sectionPrototypeCountTicketTypes', arguments);
            var config = getApiMethodMetadata(this, 'sectionPrototypeCountTicketTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Sections/{id}/seatmapImage',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('refresh')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Boolean]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeGetSeatmapImage", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Sections/{id}/ticketTypes/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeFindByIdTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Sections/{id}/ticketTypes/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeDestroyByIdTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Sections/{id}/ticketTypes/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __param(2, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, Object]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeUpdateByIdTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Sections/{id}/ticketTypes',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeGetTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Sections/{id}/ticketTypes',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeCreateTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Sections/{id}/ticketTypes',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeDeleteTicketTypes", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Sections/{id}/ticketTypes/count',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('where')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], SectionApi.prototype, "sectionPrototypeCountTicketTypes", null);
        return SectionApi;
    }(BaseApi));
    API.SectionApi = SectionApi;
    ;
    /**
     * PerformanceApi
     * @export
     */
    var PerformanceApi = /** @class */ (function (_super) {
        __extends(PerformanceApi, _super);
        function PerformanceApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary undefined
         * @param {string} code Model code
             * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        PerformanceApi.prototype.performanceGetByCode = function (code, filter) {
            var url = getFilledUrl(this, 'performanceGetByCode', arguments);
            var queryParams = getQueryParams(this, 'performanceGetByCode', arguments);
            var headerParams = getHeaderParams(this, 'performanceGetByCode', arguments);
            var body = getBody(this, 'performanceGetByCode', arguments);
            var config = getApiMethodMetadata(this, 'performanceGetByCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Performances/code/{code}',
            }),
            __param(0, PathParam('code')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], PerformanceApi.prototype, "performanceGetByCode", null);
        return PerformanceApi;
    }(BaseApi));
    API.PerformanceApi = PerformanceApi;
    ;
    /**
     * AccountApi
     * @export
     */
    var AccountApi2 = /** @class */ (function (_super) {
        __extends(AccountApi2, _super);
        function AccountApi2() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Get account by id with image urls.
         * @param {number} id Account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi2.prototype.accountGetAccount = function (id) {
            var url = getFilledUrl(this, 'accountGetAccount', arguments);
            var queryParams = getQueryParams(this, 'accountGetAccount', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAccount', arguments);
            var body = getBody(this, 'accountGetAccount', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAccount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get account by public id with download urls.
         * @param {string} publicId Public account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi2.prototype.accountGetAccountPublic = function (publicId) {
            var url = getFilledUrl(this, 'accountGetAccountPublic', arguments);
            var queryParams = getQueryParams(this, 'accountGetAccountPublic', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAccountPublic', arguments);
            var body = getBody(this, 'accountGetAccountPublic', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAccountPublic', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get account by public id with image urls.
         * @param {string} publicId Public account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi2.prototype.accountGetAccountMobile = function (publicId) {
            var url = getFilledUrl(this, 'accountGetAccountMobile', arguments);
            var queryParams = getQueryParams(this, 'accountGetAccountMobile', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAccountMobile', arguments);
            var body = getBody(this, 'accountGetAccountMobile', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAccountMobile', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {number} id
             * @param {string} path Redirect endpoint
             * @param {string} token Verification token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi2.prototype.accountRedirect = function (id, path, token) {
            var url = getFilledUrl(this, 'accountRedirect', arguments);
            var queryParams = getQueryParams(this, 'accountRedirect', arguments);
            var headerParams = getHeaderParams(this, 'accountRedirect', arguments);
            var body = getBody(this, 'accountRedirect', arguments);
            var config = getApiMethodMetadata(this, 'accountRedirect', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {string} code Model code
             * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi2.prototype.accountGetByCode = function (code, filter) {
            var url = getFilledUrl(this, 'accountGetByCode', arguments);
            var queryParams = getQueryParams(this, 'accountGetByCode', arguments);
            var headerParams = getHeaderParams(this, 'accountGetByCode', arguments);
            var body = getBody(this, 'accountGetByCode', arguments);
            var config = getApiMethodMetadata(this, 'accountGetByCode', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi2.prototype, "accountGetAccount", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{publicId}/public',
            }),
            __param(0, PathParam('publicId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AccountApi2.prototype, "accountGetAccountPublic", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{publicId}/mobile',
            }),
            __param(0, PathParam('publicId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AccountApi2.prototype, "accountGetAccountMobile", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/appLink',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('path')),
            __param(1, QueryParam('path')),
            __param(2, Required('token')),
            __param(2, QueryParam('token')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi2.prototype, "accountRedirect", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/code/{code}',
            }),
            __param(0, PathParam('code')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi2.prototype, "accountGetByCode", null);
        return AccountApi2;
    }(BaseApi));
    API.AccountApi2 = AccountApi2;
    ;
    /**
     * ClientApi
     * @export
     */
    var ClientApi = /** @class */ (function (_super) {
        __extends(ClientApi, _super);
        function ClientApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Mark client to callback
         * @param {number} id Client id
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientMarkToCallback = function (id, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientMarkToCallback', arguments);
            var queryParams = getQueryParams(this, 'clientMarkToCallback', arguments);
            var headerParams = getHeaderParams(this, 'clientMarkToCallback', arguments);
            var body = getBody(this, 'clientMarkToCallback', arguments);
            var config = getApiMethodMetadata(this, 'clientMarkToCallback', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Client login
         * @param {ClientLoginDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientLogin = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientLogin', arguments);
            var queryParams = getQueryParams(this, 'clientLogin', arguments);
            var headerParams = getHeaderParams(this, 'clientLogin', arguments);
            var body = getBody(this, 'clientLogin', arguments);
            var config = getApiMethodMetadata(this, 'clientLogin', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Verification email request
         * @param {RequestVerificationEmailDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientRequestVerificationEmail = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientRequestVerificationEmail', arguments);
            var queryParams = getQueryParams(this, 'clientRequestVerificationEmail', arguments);
            var headerParams = getHeaderParams(this, 'clientRequestVerificationEmail', arguments);
            var body = getBody(this, 'clientRequestVerificationEmail', arguments);
            var config = getApiMethodMetadata(this, 'clientRequestVerificationEmail', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Verify email
         * @param {VerifyEmailDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientVerifyEmail = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientVerifyEmail', arguments);
            var queryParams = getQueryParams(this, 'clientVerifyEmail', arguments);
            var headerParams = getHeaderParams(this, 'clientVerifyEmail', arguments);
            var body = getBody(this, 'clientVerifyEmail', arguments);
            var config = getApiMethodMetadata(this, 'clientVerifyEmail', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Client registration.
         * @param {ClientRegisterDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientRegister = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientRegister', arguments);
            var queryParams = getQueryParams(this, 'clientRegister', arguments);
            var headerParams = getHeaderParams(this, 'clientRegister', arguments);
            var body = getBody(this, 'clientRegister', arguments);
            var config = getApiMethodMetadata(this, 'clientRegister', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Send verification request
         * @param {ClientVerifyDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientVerify = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientVerify', arguments);
            var queryParams = getQueryParams(this, 'clientVerify', arguments);
            var headerParams = getHeaderParams(this, 'clientVerify', arguments);
            var body = getBody(this, 'clientVerify', arguments);
            var config = getApiMethodMetadata(this, 'clientVerify', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Client approval.
         * @param {ClientApproveDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientApprove = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientApprove', arguments);
            var queryParams = getQueryParams(this, 'clientApprove', arguments);
            var headerParams = getHeaderParams(this, 'clientApprove', arguments);
            var body = getBody(this, 'clientApprove', arguments);
            var config = getApiMethodMetadata(this, 'clientApprove', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Filter phone numbers by registered users
         * @param {FilterForUsersDto} dto undefined
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientFilterForUsers = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientFilterForUsers', arguments);
            var queryParams = getQueryParams(this, 'clientFilterForUsers', arguments);
            var headerParams = getHeaderParams(this, 'clientFilterForUsers', arguments);
            var body = getBody(this, 'clientFilterForUsers', arguments);
            var config = getApiMethodMetadata(this, 'clientFilterForUsers', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find user by phone number
         * @param {string} phoneNumber Phone number of user
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientFindUser = function (phoneNumber, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientFindUser', arguments);
            var queryParams = getQueryParams(this, 'clientFindUser', arguments);
            var headerParams = getHeaderParams(this, 'clientFindUser', arguments);
            var body = getBody(this, 'clientFindUser', arguments);
            var config = getApiMethodMetadata(this, 'clientFindUser', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Send push notification to a client
         * @param {TestPushDto} dto undefined
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientTestPush = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientTestPush', arguments);
            var queryParams = getQueryParams(this, 'clientTestPush', arguments);
            var headerParams = getHeaderParams(this, 'clientTestPush', arguments);
            var body = getBody(this, 'clientTestPush', arguments);
            var config = getApiMethodMetadata(this, 'clientTestPush', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get messages send to the client by push
         * @param {number} id Client id
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientGetMessages = function (id, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientGetMessages', arguments);
            var queryParams = getQueryParams(this, 'clientGetMessages', arguments);
            var headerParams = getHeaderParams(this, 'clientGetMessages', arguments);
            var body = getBody(this, 'clientGetMessages', arguments);
            var config = getApiMethodMetadata(this, 'clientGetMessages', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get optional consents of the client
         * @param {number} id Client id
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientGetOptionalConsents = function (id, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientGetOptionalConsents', arguments);
            var queryParams = getQueryParams(this, 'clientGetOptionalConsents', arguments);
            var headerParams = getHeaderParams(this, 'clientGetOptionalConsents', arguments);
            var body = getBody(this, 'clientGetOptionalConsents', arguments);
            var config = getApiMethodMetadata(this, 'clientGetOptionalConsents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Change an optional consent of the client
         * @param {number} id Client id
             * @param {number} consentId Consent id
             * @param {UpdateConsentDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientUpdateConsent = function (id, consentId, dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientUpdateConsent', arguments);
            var queryParams = getQueryParams(this, 'clientUpdateConsent', arguments);
            var headerParams = getHeaderParams(this, 'clientUpdateConsent', arguments);
            var body = getBody(this, 'clientUpdateConsent', arguments);
            var config = getApiMethodMetadata(this, 'clientUpdateConsent', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get held tickets by client.
         * @param {number} id Client id
             * @param {number} [baseTimestamp] undefined
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientGetTickets = function (id, baseTimestamp, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientGetTickets', arguments);
            var queryParams = getQueryParams(this, 'clientGetTickets', arguments);
            var headerParams = getHeaderParams(this, 'clientGetTickets', arguments);
            var body = getBody(this, 'clientGetTickets', arguments);
            var config = getApiMethodMetadata(this, 'clientGetTickets', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get ticket by id.
         * @param {number} id Client id
             * @param {number} ticketId Ticket id
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientGetTicket = function (id, ticketId, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientGetTicket', arguments);
            var queryParams = getQueryParams(this, 'clientGetTicket', arguments);
            var headerParams = getHeaderParams(this, 'clientGetTicket', arguments);
            var body = getBody(this, 'clientGetTicket', arguments);
            var config = getApiMethodMetadata(this, 'clientGetTicket', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get non-deleted, held ticket ids by client.
         * @param {number} id Client id
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientGetTicketListByClient = function (id, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientGetTicketListByClient', arguments);
            var queryParams = getQueryParams(this, 'clientGetTicketListByClient', arguments);
            var headerParams = getHeaderParams(this, 'clientGetTicketListByClient', arguments);
            var body = getBody(this, 'clientGetTicketListByClient', arguments);
            var config = getApiMethodMetadata(this, 'clientGetTicketListByClient', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {TransferTicketDto} dto undefined
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientTransferTicket = function (dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientTransferTicket', arguments);
            var queryParams = getQueryParams(this, 'clientTransferTicket', arguments);
            var headerParams = getHeaderParams(this, 'clientTransferTicket', arguments);
            var body = getBody(this, 'clientTransferTicket', arguments);
            var config = getApiMethodMetadata(this, 'clientTransferTicket', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get address picklist
         * @param {string} postcode UK postcode
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientGetAddressPicklist = function (postcode, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientGetAddressPicklist', arguments);
            var queryParams = getQueryParams(this, 'clientGetAddressPicklist', arguments);
            var headerParams = getHeaderParams(this, 'clientGetAddressPicklist', arguments);
            var body = getBody(this, 'clientGetAddressPicklist', arguments);
            var config = getApiMethodMetadata(this, 'clientGetAddressPicklist', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update client address
         * @param {number} id Client id
             * @param {UpdateAddressDto} [dto]
             * @param {string} [mobOsType] FanApp Operation System type: ios/android
             * @param {string} [mobOsVersion] FanApp Operation System version
             * @param {string} [mobStoreVersion] FanApp store version M.A.G.B
             * @param {string} [mobAccountPublicId] FanApp account public id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ClientApi.prototype.clientUpdateAddress = function (id, dto, mobOsType, mobOsVersion, mobStoreVersion, mobAccountPublicId) {
            var url = getFilledUrl(this, 'clientUpdateAddress', arguments);
            var queryParams = getQueryParams(this, 'clientUpdateAddress', arguments);
            var headerParams = getHeaderParams(this, 'clientUpdateAddress', arguments);
            var body = getBody(this, 'clientUpdateAddress', arguments);
            var config = getApiMethodMetadata(this, 'clientUpdateAddress', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Clients/{id}/markToCallback',
            }),
            __param(0, PathParam('id')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientMarkToCallback", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/login',
            }),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientLogin", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/requestVerificationEmail',
            }),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientRequestVerificationEmail", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/verifyEmail',
            }),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientVerifyEmail", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/register',
            }),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientRegister", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/verify',
            }),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientVerify", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/approve',
            }),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientApprove", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/filterForUsers',
            }),
            __param(0, Required('dto')),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientFilterForUsers", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Clients/findUser',
            }),
            __param(0, Required('phoneNumber')),
            __param(0, QueryParam('phoneNumber')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientFindUser", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/testPush',
            }),
            __param(0, Required('dto')),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientTestPush", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Clients/{id}/messages',
            }),
            __param(0, PathParam('id')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientGetMessages", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Clients/{id}/optionalConsents',
            }),
            __param(0, PathParam('id')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientGetOptionalConsents", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Clients/{id}/consent/{consentId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('consentId')),
            __param(2, Body('dto')),
            __param(3, HeaderParam('mob-os-type')),
            __param(4, HeaderParam('mob-os-version')),
            __param(5, HeaderParam('mob-store-version')),
            __param(6, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientUpdateConsent", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Clients/{id}/tickets',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('baseTimestamp')),
            __param(2, HeaderParam('mob-os-type')),
            __param(3, HeaderParam('mob-os-version')),
            __param(4, HeaderParam('mob-store-version')),
            __param(5, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientGetTickets", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Clients/{id}/ticket/{ticketId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketId')),
            __param(2, HeaderParam('mob-os-type')),
            __param(3, HeaderParam('mob-os-version')),
            __param(4, HeaderParam('mob-store-version')),
            __param(5, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientGetTicket", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Clients/{id}/ticketList',
            }),
            __param(0, PathParam('id')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientGetTicketListByClient", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Clients/transferTicket',
            }),
            __param(0, Required('dto')),
            __param(0, Body('dto')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientTransferTicket", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Clients/addresspicklist/{postcode}',
            }),
            __param(0, PathParam('postcode')),
            __param(1, HeaderParam('mob-os-type')),
            __param(2, HeaderParam('mob-os-version')),
            __param(3, HeaderParam('mob-store-version')),
            __param(4, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientGetAddressPicklist", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Clients/{id}/address',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('dto')),
            __param(2, HeaderParam('mob-os-type')),
            __param(3, HeaderParam('mob-os-version')),
            __param(4, HeaderParam('mob-store-version')),
            __param(5, HeaderParam('mob-account-public-id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object, String, String, String, String]),
            __metadata("design:returntype", Promise)
        ], ClientApi.prototype, "clientUpdateAddress", null);
        return ClientApi;
    }(BaseApi));
    API.ClientApi = ClientApi;
    ;
    /**
     * AccountApi
     * @export
     */
    var AccountApi = /** @class */ (function (_super) {
        __extends(AccountApi, _super);
        function AccountApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Fetches belongsTo relation defaultAccount.
         * @param {string} id Account id
             * @param {boolean} [refresh] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeGetDefaultAccount = function (id, refresh) {
            var url = getFilledUrl(this, 'accountPrototypeGetDefaultAccount', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeGetDefaultAccount', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeGetDefaultAccount', arguments);
            var body = getBody(this, 'accountPrototypeGetDefaultAccount', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeGetDefaultAccount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete a related item by id for performances.
         * @param {string} id Account id
             * @param {string} fk Foreign key for performances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeDestroyByIdPerformances = function (id, fk) {
            var url = getFilledUrl(this, 'accountPrototypeDestroyByIdPerformances', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeDestroyByIdPerformances', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeDestroyByIdPerformances', arguments);
            var body = getBody(this, 'accountPrototypeDestroyByIdPerformances', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeDestroyByIdPerformances', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update a related item by id for performances.
         * @param {string} id Account id
             * @param {string} fk Foreign key for performances
             * @param {Performance} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeUpdateByIdPerformances = function (id, fk, data) {
            var url = getFilledUrl(this, 'accountPrototypeUpdateByIdPerformances', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeUpdateByIdPerformances', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeUpdateByIdPerformances', arguments);
            var body = getBody(this, 'accountPrototypeUpdateByIdPerformances', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeUpdateByIdPerformances', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete a related item by id for venues.
         * @param {string} id Account id
             * @param {string} fk Foreign key for venues
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeDestroyByIdVenues = function (id, fk) {
            var url = getFilledUrl(this, 'accountPrototypeDestroyByIdVenues', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeDestroyByIdVenues', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeDestroyByIdVenues', arguments);
            var body = getBody(this, 'accountPrototypeDestroyByIdVenues', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeDestroyByIdVenues', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update a related item by id for venues.
         * @param {string} id Account id
             * @param {string} fk Foreign key for venues
             * @param {Venue} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeUpdateByIdVenues = function (id, fk, data) {
            var url = getFilledUrl(this, 'accountPrototypeUpdateByIdVenues', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeUpdateByIdVenues', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeUpdateByIdVenues', arguments);
            var body = getBody(this, 'accountPrototypeUpdateByIdVenues', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeUpdateByIdVenues', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete a related item by id for events.
         * @param {string} id Account id
             * @param {string} fk Foreign key for events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeDestroyByIdEvents = function (id, fk) {
            var url = getFilledUrl(this, 'accountPrototypeDestroyByIdEvents', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeDestroyByIdEvents', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeDestroyByIdEvents', arguments);
            var body = getBody(this, 'accountPrototypeDestroyByIdEvents', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeDestroyByIdEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update a related item by id for events.
         * @param {string} id Account id
             * @param {string} fk Foreign key for events
             * @param {Event} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeUpdateByIdEvents = function (id, fk, data) {
            var url = getFilledUrl(this, 'accountPrototypeUpdateByIdEvents', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeUpdateByIdEvents', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeUpdateByIdEvents', arguments);
            var body = getBody(this, 'accountPrototypeUpdateByIdEvents', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeUpdateByIdEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Fetches belongsTo relation companyLogo.
         * @param {string} id Account id
             * @param {boolean} [refresh] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeGetCompanyLogo = function (id, refresh) {
            var url = getFilledUrl(this, 'accountPrototypeGetCompanyLogo', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeGetCompanyLogo', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeGetCompanyLogo', arguments);
            var body = getBody(this, 'accountPrototypeGetCompanyLogo', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeGetCompanyLogo', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Queries performances of Account.
         * @param {string} id Account id
             * @param {string} [filter] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeGetPerformances = function (id, filter) {
            var url = getFilledUrl(this, 'accountPrototypeGetPerformances', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeGetPerformances', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeGetPerformances', arguments);
            var body = getBody(this, 'accountPrototypeGetPerformances', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeGetPerformances', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Creates a new instance in performances of this model.
         * @param {string} id Account id
             * @param {Performance} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeCreatePerformances = function (id, data) {
            var url = getFilledUrl(this, 'accountPrototypeCreatePerformances', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeCreatePerformances', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeCreatePerformances', arguments);
            var body = getBody(this, 'accountPrototypeCreatePerformances', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeCreatePerformances', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Counts performances of Account.
         * @param {string} id Account id
             * @param {string} [where] Criteria to match model instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeCountPerformances = function (id, where) {
            var url = getFilledUrl(this, 'accountPrototypeCountPerformances', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeCountPerformances', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeCountPerformances', arguments);
            var body = getBody(this, 'accountPrototypeCountPerformances', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeCountPerformances', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Queries venues of Account.
         * @param {string} id Account id
             * @param {string} [filter] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeGetVenues = function (id, filter) {
            var url = getFilledUrl(this, 'accountPrototypeGetVenues', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeGetVenues', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeGetVenues', arguments);
            var body = getBody(this, 'accountPrototypeGetVenues', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeGetVenues', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Creates a new instance in venues of this model.
         * @param {string} id Account id
             * @param {Venue} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeCreateVenues = function (id, data) {
            var url = getFilledUrl(this, 'accountPrototypeCreateVenues', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeCreateVenues', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeCreateVenues', arguments);
            var body = getBody(this, 'accountPrototypeCreateVenues', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeCreateVenues', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Counts venues of Account.
         * @param {string} id Account id
             * @param {string} [where] Criteria to match model instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeCountVenues = function (id, where) {
            var url = getFilledUrl(this, 'accountPrototypeCountVenues', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeCountVenues', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeCountVenues', arguments);
            var body = getBody(this, 'accountPrototypeCountVenues', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeCountVenues', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Queries events of Account.
         * @param {string} id Account id
             * @param {string} [filter] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeGetEvents = function (id, filter) {
            var url = getFilledUrl(this, 'accountPrototypeGetEvents', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeGetEvents', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeGetEvents', arguments);
            var body = getBody(this, 'accountPrototypeGetEvents', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeGetEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Creates a new instance in events of this model.
         * @param {string} id Account id
             * @param {Event} [data] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeCreateEvents = function (id, data) {
            var url = getFilledUrl(this, 'accountPrototypeCreateEvents', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeCreateEvents', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeCreateEvents', arguments);
            var body = getBody(this, 'accountPrototypeCreateEvents', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeCreateEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Counts events of Account.
         * @param {string} id Account id
             * @param {string} [where] Criteria to match model instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountPrototypeCountEvents = function (id, where) {
            var url = getFilledUrl(this, 'accountPrototypeCountEvents', arguments);
            var queryParams = getQueryParams(this, 'accountPrototypeCountEvents', arguments);
            var headerParams = getHeaderParams(this, 'accountPrototypeCountEvents', arguments);
            var body = getBody(this, 'accountPrototypeCountEvents', arguments);
            var config = getApiMethodMetadata(this, 'accountPrototypeCountEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Create a new instance of the model and persist it into the data source.
         * @param {Account} [data] Model instance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCreate = function (data) {
            var url = getFilledUrl(this, 'accountCreate', arguments);
            var queryParams = getQueryParams(this, 'accountCreate', arguments);
            var headerParams = getHeaderParams(this, 'accountCreate', arguments);
            var body = getBody(this, 'accountCreate', arguments);
            var config = getApiMethodMetadata(this, 'accountCreate', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get masked name of the client by phone number.
         * @param {string} webKey Public identifier key of account.
             * @param {string} phoneNumber Client's phone number in E.164 format.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetClientInfo = function (webKey, phoneNumber) {
            var url = getFilledUrl(this, 'accountGetClientInfo', arguments);
            var queryParams = getQueryParams(this, 'accountGetClientInfo', arguments);
            var headerParams = getHeaderParams(this, 'accountGetClientInfo', arguments);
            var body = getBody(this, 'accountGetClientInfo', arguments);
            var config = getApiMethodMetadata(this, 'accountGetClientInfo', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get account information for UI.
         * @param {string} webKey Public identifier key of account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetAccountInfo = function (webKey) {
            var url = getFilledUrl(this, 'accountGetAccountInfo', arguments);
            var queryParams = getQueryParams(this, 'accountGetAccountInfo', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAccountInfo', arguments);
            var body = getBody(this, 'accountGetAccountInfo', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAccountInfo', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Send invitation to client.
         * @param {string} webKey Public identifier key of account.
             * @param {string} phoneNumber Client's phone number in E.164 format.
             * @param {string} requestId Invitation sending identifier for MQ feedback.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountInviteClient = function (webKey, phoneNumber, requestId) {
            var url = getFilledUrl(this, 'accountInviteClient', arguments);
            var queryParams = getQueryParams(this, 'accountInviteClient', arguments);
            var headerParams = getHeaderParams(this, 'accountInviteClient', arguments);
            var body = getBody(this, 'accountInviteClient', arguments);
            var config = getApiMethodMetadata(this, 'accountInviteClient', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Order tickets
         * @param {string} serverKey Private identifier key of account.
             * @param {string} authorization Bearer token (secret) of account.
             * @param {OrderTicketDto} dto Ticket order request data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountOrderTickets = function (serverKey, authorization, dto) {
            var url = getFilledUrl(this, 'accountOrderTickets', arguments);
            var queryParams = getQueryParams(this, 'accountOrderTickets', arguments);
            var headerParams = getHeaderParams(this, 'accountOrderTickets', arguments);
            var body = getBody(this, 'accountOrderTickets', arguments);
            var config = getApiMethodMetadata(this, 'accountOrderTickets', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Confirm ticket order transaction
         * @param {string} serverKey Private identifier key of account
             * @param {string} transactionId Identifier of transction of ticket order.
             * @param {string} authorization Bearer token (secret) of account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountConfirmTransaction = function (serverKey, transactionId, authorization) {
            var url = getFilledUrl(this, 'accountConfirmTransaction', arguments);
            var queryParams = getQueryParams(this, 'accountConfirmTransaction', arguments);
            var headerParams = getHeaderParams(this, 'accountConfirmTransaction', arguments);
            var body = getBody(this, 'accountConfirmTransaction', arguments);
            var config = getApiMethodMetadata(this, 'accountConfirmTransaction', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Cancel ticket order transaction
         * @param {string} serverKey Private identifier key of account
             * @param {string} transactionId Identifier of transction of ticket order.
             * @param {string} authorization Bearer token (secret) of account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCancelTransaction = function (serverKey, transactionId, authorization) {
            var url = getFilledUrl(this, 'accountCancelTransaction', arguments);
            var queryParams = getQueryParams(this, 'accountCancelTransaction', arguments);
            var headerParams = getHeaderParams(this, 'accountCancelTransaction', arguments);
            var body = getBody(this, 'accountCancelTransaction', arguments);
            var config = getApiMethodMetadata(this, 'accountCancelTransaction', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Check ticket transaction status
         * @param {string} serverKey Private identifier key of account
             * @param {string} transactionId Identifier of transction of ticket order.
             * @param {string} authorization Bearer token (secret) of account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCheckTransactionStatus = function (serverKey, transactionId, authorization) {
            var url = getFilledUrl(this, 'accountCheckTransactionStatus', arguments);
            var queryParams = getQueryParams(this, 'accountCheckTransactionStatus', arguments);
            var headerParams = getHeaderParams(this, 'accountCheckTransactionStatus', arguments);
            var body = getBody(this, 'accountCheckTransactionStatus', arguments);
            var config = getApiMethodMetadata(this, 'accountCheckTransactionStatus', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get backend version from package.json
        
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetBackendVersion = function () {
            var url = getFilledUrl(this, 'accountGetBackendVersion', arguments);
            var queryParams = getQueryParams(this, 'accountGetBackendVersion', arguments);
            var headerParams = getHeaderParams(this, 'accountGetBackendVersion', arguments);
            var body = getBody(this, 'accountGetBackendVersion', arguments);
            var config = getApiMethodMetadata(this, 'accountGetBackendVersion', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request ticket statistics of an event
         * @param {number} id undefined
             * @param {number} eventId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetEventTicketStats = function (id, eventId) {
            var url = getFilledUrl(this, 'accountGetEventTicketStats', arguments);
            var queryParams = getQueryParams(this, 'accountGetEventTicketStats', arguments);
            var headerParams = getHeaderParams(this, 'accountGetEventTicketStats', arguments);
            var body = getBody(this, 'accountGetEventTicketStats', arguments);
            var config = getApiMethodMetadata(this, 'accountGetEventTicketStats', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Create a manager belonging to a account
         * @param {number} accountId undefined
             * @param {ManagerCreateDto} [data]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountAddManager = function (accountId, data) {
            var url = getFilledUrl(this, 'accountAddManager', arguments);
            var queryParams = getQueryParams(this, 'accountAddManager', arguments);
            var headerParams = getHeaderParams(this, 'accountAddManager', arguments);
            var body = getBody(this, 'accountAddManager', arguments);
            var config = getApiMethodMetadata(this, 'accountAddManager', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Add or create agent.
         * @param {number} accountId undefined
             * @param {AddAgentDto} [data]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountAddAgent = function (accountId, data) {
            var url = getFilledUrl(this, 'accountAddAgent', arguments);
            var queryParams = getQueryParams(this, 'accountAddAgent', arguments);
            var headerParams = getHeaderParams(this, 'accountAddAgent', arguments);
            var body = getBody(this, 'accountAddAgent', arguments);
            var config = getApiMethodMetadata(this, 'accountAddAgent', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Send push notification to all users of the account who has ticket with the given ticket type.
         * @param {number} id undefined
             * @param {number} ticketTypeId undefined
             * @param {SendPushByTicketTypeDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountSendPushByTicketType = function (id, ticketTypeId, dto) {
            var url = getFilledUrl(this, 'accountSendPushByTicketType', arguments);
            var queryParams = getQueryParams(this, 'accountSendPushByTicketType', arguments);
            var headerParams = getHeaderParams(this, 'accountSendPushByTicketType', arguments);
            var body = getBody(this, 'accountSendPushByTicketType', arguments);
            var config = getApiMethodMetadata(this, 'accountSendPushByTicketType', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Rename TicketType's TicketHeaderFields. This change ticketHeaderFieldNames, tmFieldMapping and migrate all existing ticket by proper customFields. Returns with count of updated tickets.
         * @param {number} id Account id
             * @param {number} ticketTypeId TicketType id
             * @param {any} fieldMapping Mapping object for TicketHeaderFields. Format: { 'current_field_name': 'new_field_name', ... }
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountRenameTicketTypeTicketHeaderFields = function (id, ticketTypeId, fieldMapping) {
            var url = getFilledUrl(this, 'accountRenameTicketTypeTicketHeaderFields', arguments);
            var queryParams = getQueryParams(this, 'accountRenameTicketTypeTicketHeaderFields', arguments);
            var headerParams = getHeaderParams(this, 'accountRenameTicketTypeTicketHeaderFields', arguments);
            var body = getBody(this, 'accountRenameTicketTypeTicketHeaderFields', arguments);
            var config = getApiMethodMetadata(this, 'accountRenameTicketTypeTicketHeaderFields', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Rename TicketType's TicketHeaderFields Bulk. This change ticketHeaderFieldNames, tmFieldMapping and migrate all existing ticket by proper customFields. Returns with count of updated tickets.
         * @param {number} id Account id
             * @param {string} ticketTypeIdList List of TicketType id (selected for rename)
             * @param {any} fieldMapping Mapping object for TicketHeaderFields. Format: { 'current_field_name': 'new_field_name', ... }
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountRenameTicketTypeTicketHeaderFieldsBulk = function (id, ticketTypeIdList, fieldMapping) {
            var url = getFilledUrl(this, 'accountRenameTicketTypeTicketHeaderFieldsBulk', arguments);
            var queryParams = getQueryParams(this, 'accountRenameTicketTypeTicketHeaderFieldsBulk', arguments);
            var headerParams = getHeaderParams(this, 'accountRenameTicketTypeTicketHeaderFieldsBulk', arguments);
            var body = getBody(this, 'accountRenameTicketTypeTicketHeaderFieldsBulk', arguments);
            var config = getApiMethodMetadata(this, 'accountRenameTicketTypeTicketHeaderFieldsBulk', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Create ticket records.
         * @param {number} id undefined
             * @param {CreateTicketsDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCreateTicket = function (id, dto) {
            var url = getFilledUrl(this, 'accountCreateTicket', arguments);
            var queryParams = getQueryParams(this, 'accountCreateTicket', arguments);
            var headerParams = getHeaderParams(this, 'accountCreateTicket', arguments);
            var body = getBody(this, 'accountCreateTicket', arguments);
            var config = getApiMethodMetadata(this, 'accountCreateTicket', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update specified ticket record.
         * @param {number} id undefined
             * @param {number} ticketId undefined
             * @param {UpdateTicketDetailsDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountUpdateTicketById = function (id, ticketId, dto) {
            var url = getFilledUrl(this, 'accountUpdateTicketById', arguments);
            var queryParams = getQueryParams(this, 'accountUpdateTicketById', arguments);
            var headerParams = getHeaderParams(this, 'accountUpdateTicketById', arguments);
            var body = getBody(this, 'accountUpdateTicketById', arguments);
            var config = getApiMethodMetadata(this, 'accountUpdateTicketById', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {number} id undefined
             * @param {number} ticketId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountDeleteTicket = function (id, ticketId) {
            var url = getFilledUrl(this, 'accountDeleteTicket', arguments);
            var queryParams = getQueryParams(this, 'accountDeleteTicket', arguments);
            var headerParams = getHeaderParams(this, 'accountDeleteTicket', arguments);
            var body = getBody(this, 'accountDeleteTicket', arguments);
            var config = getApiMethodMetadata(this, 'accountDeleteTicket', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request tickets of account of account
         * @param {number} id undefined
             * @param {string} [order] undefined
             * @param {number} [page] undefined
             * @param {number} [limit] undefined
             * @param {number} [ticketId] undefined
             * @param {number} [eventId] undefined
             * @param {number} [clientId] undefined
             * @param {string} [text] undefined
             * @param {string} [deliveryStatus] undefined
             * @param {boolean} [isConsumed] undefined
             * @param {boolean} [isDeleted] undefined
             * @param {boolean} [isConflicting] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetTickets = function (id, order, page, limit, ticketId, eventId, clientId, text, deliveryStatus, isConsumed, isDeleted, isConflicting) {
            var url = getFilledUrl(this, 'accountGetTickets', arguments);
            var queryParams = getQueryParams(this, 'accountGetTickets', arguments);
            var headerParams = getHeaderParams(this, 'accountGetTickets', arguments);
            var body = getBody(this, 'accountGetTickets', arguments);
            var config = getApiMethodMetadata(this, 'accountGetTickets', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Count tickets of account of account
         * @param {number} id undefined
             * @param {number} [ticketId] undefined
             * @param {number} [eventId] undefined
             * @param {number} [clientId] undefined
             * @param {string} [text] undefined
             * @param {string} [deliveryStatus] undefined
             * @param {boolean} [isConsumed] undefined
             * @param {boolean} [isDeleted] undefined
             * @param {boolean} [isConflicting] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCountTickets = function (id, ticketId, eventId, clientId, text, deliveryStatus, isConsumed, isDeleted, isConflicting) {
            var url = getFilledUrl(this, 'accountCountTickets', arguments);
            var queryParams = getQueryParams(this, 'accountCountTickets', arguments);
            var headerParams = getHeaderParams(this, 'accountCountTickets', arguments);
            var body = getBody(this, 'accountCountTickets', arguments);
            var config = getApiMethodMetadata(this, 'accountCountTickets', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get tickets of a client
         * @param {number} id undefined
             * @param {number} clientId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetTicketsByClient = function (id, clientId) {
            var url = getFilledUrl(this, 'accountGetTicketsByClient', arguments);
            var queryParams = getQueryParams(this, 'accountGetTicketsByClient', arguments);
            var headerParams = getHeaderParams(this, 'accountGetTicketsByClient', arguments);
            var body = getBody(this, 'accountGetTicketsByClient', arguments);
            var config = getApiMethodMetadata(this, 'accountGetTicketsByClient', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {number} id undefined
             * @param {number} ticketId undefined
             * @param {ChangeTicketHolderDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountChangeTicketHolder = function (id, ticketId, dto) {
            var url = getFilledUrl(this, 'accountChangeTicketHolder', arguments);
            var queryParams = getQueryParams(this, 'accountChangeTicketHolder', arguments);
            var headerParams = getHeaderParams(this, 'accountChangeTicketHolder', arguments);
            var body = getBody(this, 'accountChangeTicketHolder', arguments);
            var config = getApiMethodMetadata(this, 'accountChangeTicketHolder', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {number} id undefined
             * @param {number} ticketId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountResendSms = function (id, ticketId) {
            var url = getFilledUrl(this, 'accountResendSms', arguments);
            var queryParams = getQueryParams(this, 'accountResendSms', arguments);
            var headerParams = getHeaderParams(this, 'accountResendSms', arguments);
            var body = getBody(this, 'accountResendSms', arguments);
            var config = getApiMethodMetadata(this, 'accountResendSms', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary undefined
         * @param {number} id undefined
             * @param {number} ticketId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountResendPush = function (id, ticketId) {
            var url = getFilledUrl(this, 'accountResendPush', arguments);
            var queryParams = getQueryParams(this, 'accountResendPush', arguments);
            var headerParams = getHeaderParams(this, 'accountResendPush', arguments);
            var body = getBody(this, 'accountResendPush', arguments);
            var config = getApiMethodMetadata(this, 'accountResendPush', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Validate tickets uploaded by batch uploader.
         * @param {number} id undefined
             * @param {CreateTicketsDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountValidateTickets = function (id, dto) {
            var url = getFilledUrl(this, 'accountValidateTickets', arguments);
            var queryParams = getQueryParams(this, 'accountValidateTickets', arguments);
            var headerParams = getHeaderParams(this, 'accountValidateTickets', arguments);
            var body = getBody(this, 'accountValidateTickets', arguments);
            var config = getApiMethodMetadata(this, 'accountValidateTickets', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request clients of account of account
         * @param {number} id undefined
             * @param {string} [order] undefined
             * @param {number} [page] undefined
             * @param {number} [limit] undefined
             * @param {number} [clientId] undefined
             * @param {string} [text] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetClients = function (id, order, page, limit, clientId, text) {
            var url = getFilledUrl(this, 'accountGetClients', arguments);
            var queryParams = getQueryParams(this, 'accountGetClients', arguments);
            var headerParams = getHeaderParams(this, 'accountGetClients', arguments);
            var body = getBody(this, 'accountGetClients', arguments);
            var config = getApiMethodMetadata(this, 'accountGetClients', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Count clients of account of account
         * @param {number} id undefined
             * @param {number} [clientId] undefined
             * @param {string} [text] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCountClients = function (id, clientId, text) {
            var url = getFilledUrl(this, 'accountCountClients', arguments);
            var queryParams = getQueryParams(this, 'accountCountClients', arguments);
            var headerParams = getHeaderParams(this, 'accountCountClients', arguments);
            var body = getBody(this, 'accountCountClients', arguments);
            var config = getApiMethodMetadata(this, 'accountCountClients', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request clients to call back
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetClientsToCall = function (id) {
            var url = getFilledUrl(this, 'accountGetClientsToCall', arguments);
            var queryParams = getQueryParams(this, 'accountGetClientsToCall', arguments);
            var headerParams = getHeaderParams(this, 'accountGetClientsToCall', arguments);
            var body = getBody(this, 'accountGetClientsToCall', arguments);
            var config = getApiMethodMetadata(this, 'accountGetClientsToCall', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Set client call back flag to false.
         * @param {number} id undefined
             * @param {number} clientId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountClientCalled = function (id, clientId) {
            var url = getFilledUrl(this, 'accountClientCalled', arguments);
            var queryParams = getQueryParams(this, 'accountClientCalled', arguments);
            var headerParams = getHeaderParams(this, 'accountClientCalled', arguments);
            var body = getBody(this, 'accountClientCalled', arguments);
            var config = getApiMethodMetadata(this, 'accountClientCalled', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request client data by phone-number of registered client
         * @param {number} id undefined
             * @param {string} phoneNumber undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetClientByPhoneNumber = function (id, phoneNumber) {
            var url = getFilledUrl(this, 'accountGetClientByPhoneNumber', arguments);
            var queryParams = getQueryParams(this, 'accountGetClientByPhoneNumber', arguments);
            var headerParams = getHeaderParams(this, 'accountGetClientByPhoneNumber', arguments);
            var body = getBody(this, 'accountGetClientByPhoneNumber', arguments);
            var config = getApiMethodMetadata(this, 'accountGetClientByPhoneNumber', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Approve unverified email of a client
         * @param {number} id undefined
             * @param {number} clientId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountApproveClientEmail = function (id, clientId) {
            var url = getFilledUrl(this, 'accountApproveClientEmail', arguments);
            var queryParams = getQueryParams(this, 'accountApproveClientEmail', arguments);
            var headerParams = getHeaderParams(this, 'accountApproveClientEmail', arguments);
            var body = getBody(this, 'accountApproveClientEmail', arguments);
            var config = getApiMethodMetadata(this, 'accountApproveClientEmail', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Get client details by id.
         * @param {number} id undefined
             * @param {number} clientId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetClient = function (id, clientId) {
            var url = getFilledUrl(this, 'accountGetClient', arguments);
            var queryParams = getQueryParams(this, 'accountGetClient', arguments);
            var headerParams = getHeaderParams(this, 'accountGetClient', arguments);
            var body = getBody(this, 'accountGetClient', arguments);
            var config = getApiMethodMetadata(this, 'accountGetClient', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update updatable attributes of the specified client record.
         * @param {number} id undefined
             * @param {number} clientId undefined
             * @param {ClientDetails} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountUpdateClient = function (id, clientId, dto) {
            var url = getFilledUrl(this, 'accountUpdateClient', arguments);
            var queryParams = getQueryParams(this, 'accountUpdateClient', arguments);
            var headerParams = getHeaderParams(this, 'accountUpdateClient', arguments);
            var body = getBody(this, 'accountUpdateClient', arguments);
            var config = getApiMethodMetadata(this, 'accountUpdateClient', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request verification email for a user.
         * @param {number} id undefined
             * @param {number} clientId undefined
             * @param {AccountRequestVerificationEmailDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountRequestVerificationEmail = function (id, clientId, dto) {
            var url = getFilledUrl(this, 'accountRequestVerificationEmail', arguments);
            var queryParams = getQueryParams(this, 'accountRequestVerificationEmail', arguments);
            var headerParams = getHeaderParams(this, 'accountRequestVerificationEmail', arguments);
            var body = getBody(this, 'accountRequestVerificationEmail', arguments);
            var config = getApiMethodMetadata(this, 'accountRequestVerificationEmail', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request agents of account
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetAgents = function (id) {
            var url = getFilledUrl(this, 'accountGetAgents', arguments);
            var queryParams = getQueryParams(this, 'accountGetAgents', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAgents', arguments);
            var body = getBody(this, 'accountGetAgents', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAgents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request event data
         * @param {number} id undefined
             * @param {number} eventId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetAccountEvent = function (id, eventId) {
            var url = getFilledUrl(this, 'accountGetAccountEvent', arguments);
            var queryParams = getQueryParams(this, 'accountGetAccountEvent', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAccountEvent', arguments);
            var body = getBody(this, 'accountGetAccountEvent', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAccountEvent', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request events of account
         * @param {number} id undefined
             * @param {string} [where] undefined
             * @param {boolean} [includeTicketCount] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetAccountEvents = function (id, where, includeTicketCount) {
            var url = getFilledUrl(this, 'accountGetAccountEvents', arguments);
            var queryParams = getQueryParams(this, 'accountGetAccountEvents', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAccountEvents', arguments);
            var body = getBody(this, 'accountGetAccountEvents', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAccountEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Notifies all ticket owners of a Ticketmaster-related event.
         * @param {number} id undefined
             * @param {number} eventId undefined
             * @param {NotifyClientsDto} [dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountNotifyClients = function (id, eventId, dto) {
            var url = getFilledUrl(this, 'accountNotifyClients', arguments);
            var queryParams = getQueryParams(this, 'accountNotifyClients', arguments);
            var headerParams = getHeaderParams(this, 'accountNotifyClients', arguments);
            var body = getBody(this, 'accountNotifyClients', arguments);
            var config = getApiMethodMetadata(this, 'accountNotifyClients', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request logs
         * @param {number} id undefined
             * @param {string} modelName undefined
             * @param {number} modelId undefined
             * @param {string} [order] undefined
             * @param {number} [page] undefined
             * @param {number} [limit] undefined
             * @param {number} [ticketId] undefined
             * @param {number} [eventId] undefined
             * @param {number} [clientId] undefined
             * @param {string} [text] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetLogs = function (id, modelName, modelId, order, page, limit, ticketId, eventId, clientId, text) {
            var url = getFilledUrl(this, 'accountGetLogs', arguments);
            var queryParams = getQueryParams(this, 'accountGetLogs', arguments);
            var headerParams = getHeaderParams(this, 'accountGetLogs', arguments);
            var body = getBody(this, 'accountGetLogs', arguments);
            var config = getApiMethodMetadata(this, 'accountGetLogs', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Count clients of account of account
         * @param {number} id undefined
             * @param {string} modelName undefined
             * @param {number} modelId undefined
             * @param {number} [ticketId] undefined
             * @param {number} [eventId] undefined
             * @param {number} [clientId] undefined
             * @param {string} [text] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCountLogs = function (id, modelName, modelId, ticketId, eventId, clientId, text) {
            var url = getFilledUrl(this, 'accountCountLogs', arguments);
            var queryParams = getQueryParams(this, 'accountCountLogs', arguments);
            var headerParams = getHeaderParams(this, 'accountCountLogs', arguments);
            var body = getBody(this, 'accountCountLogs', arguments);
            var config = getApiMethodMetadata(this, 'accountCountLogs', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request venue data of account
         * @param {number} id undefined
             * @param {number} venueId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetVenue = function (id, venueId) {
            var url = getFilledUrl(this, 'accountGetVenue', arguments);
            var queryParams = getQueryParams(this, 'accountGetVenue', arguments);
            var headerParams = getHeaderParams(this, 'accountGetVenue', arguments);
            var body = getBody(this, 'accountGetVenue', arguments);
            var config = getApiMethodMetadata(this, 'accountGetVenue', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request venues of account
         * @param {number} id undefined
             * @param {string} [where] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetVenues = function (id, where) {
            var url = getFilledUrl(this, 'accountGetVenues', arguments);
            var queryParams = getQueryParams(this, 'accountGetVenues', arguments);
            var headerParams = getHeaderParams(this, 'accountGetVenues', arguments);
            var body = getBody(this, 'accountGetVenues', arguments);
            var config = getApiMethodMetadata(this, 'accountGetVenues', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Create a section record.
         * @param {number} id undefined
             * @param {number} venueId undefined
             * @param {CreateSectionDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCreateSection = function (id, venueId, dto) {
            var url = getFilledUrl(this, 'accountCreateSection', arguments);
            var queryParams = getQueryParams(this, 'accountCreateSection', arguments);
            var headerParams = getHeaderParams(this, 'accountCreateSection', arguments);
            var body = getBody(this, 'accountCreateSection', arguments);
            var config = getApiMethodMetadata(this, 'accountCreateSection', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request sections of a venue.
         * @param {number} id undefined
             * @param {number} venueId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetSectionsByVenue = function (id, venueId) {
            var url = getFilledUrl(this, 'accountGetSectionsByVenue', arguments);
            var queryParams = getQueryParams(this, 'accountGetSectionsByVenue', arguments);
            var headerParams = getHeaderParams(this, 'accountGetSectionsByVenue', arguments);
            var body = getBody(this, 'accountGetSectionsByVenue', arguments);
            var config = getApiMethodMetadata(this, 'accountGetSectionsByVenue', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update specified section record.
         * @param {number} id undefined
             * @param {number} venueId undefined
             * @param {number} sectionId undefined
             * @param {UpdateSectionDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountUpdateSection = function (id, venueId, sectionId, dto) {
            var url = getFilledUrl(this, 'accountUpdateSection', arguments);
            var queryParams = getQueryParams(this, 'accountUpdateSection', arguments);
            var headerParams = getHeaderParams(this, 'accountUpdateSection', arguments);
            var body = getBody(this, 'accountUpdateSection', arguments);
            var config = getApiMethodMetadata(this, 'accountUpdateSection', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete specified section record.
         * @param {number} id undefined
             * @param {number} venueId undefined
             * @param {number} sectionId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountDeleteSection = function (id, venueId, sectionId) {
            var url = getFilledUrl(this, 'accountDeleteSection', arguments);
            var queryParams = getQueryParams(this, 'accountDeleteSection', arguments);
            var headerParams = getHeaderParams(this, 'accountDeleteSection', arguments);
            var body = getBody(this, 'accountDeleteSection', arguments);
            var config = getApiMethodMetadata(this, 'accountDeleteSection', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request account data
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetAccount = function (id) {
            var url = getFilledUrl(this, 'accountGetAccount', arguments);
            var queryParams = getQueryParams(this, 'accountGetAccount', arguments);
            var headerParams = getHeaderParams(this, 'accountGetAccount', arguments);
            var body = getBody(this, 'accountGetAccount', arguments);
            var config = getApiMethodMetadata(this, 'accountGetAccount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update account of account
         * @param {number} id undefined
             * @param {AccountUpdateDto} dto undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountUpdateAccount = function (id, dto) {
            var url = getFilledUrl(this, 'accountUpdateAccount', arguments);
            var queryParams = getQueryParams(this, 'accountUpdateAccount', arguments);
            var headerParams = getHeaderParams(this, 'accountUpdateAccount', arguments);
            var body = getBody(this, 'accountUpdateAccount', arguments);
            var config = getApiMethodMetadata(this, 'accountUpdateAccount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request ticketmaster events of account
         * @param {number} id undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetTmEvents = function (id) {
            var url = getFilledUrl(this, 'accountGetTmEvents', arguments);
            var queryParams = getQueryParams(this, 'accountGetTmEvents', arguments);
            var headerParams = getHeaderParams(this, 'accountGetTmEvents', arguments);
            var body = getBody(this, 'accountGetTmEvents', arguments);
            var config = getApiMethodMetadata(this, 'accountGetTmEvents', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Request ticketmaster pricetypes of an event
         * @param {number} id undefined
             * @param {number} eventId undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountGetTmPriceTypes = function (id, eventId) {
            var url = getFilledUrl(this, 'accountGetTmPriceTypes', arguments);
            var queryParams = getQueryParams(this, 'accountGetTmPriceTypes', arguments);
            var headerParams = getHeaderParams(this, 'accountGetTmPriceTypes', arguments);
            var body = getBody(this, 'accountGetTmPriceTypes', arguments);
            var config = getApiMethodMetadata(this, 'accountGetTmPriceTypes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Check ticket transaction status
         * @param {number} id undefined
             * @param {string} transactionId Identifier of transction of ticket order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AccountApi.prototype.accountCheckTicketsTransactionStatus = function (id, transactionId) {
            var url = getFilledUrl(this, 'accountCheckTicketsTransactionStatus', arguments);
            var queryParams = getQueryParams(this, 'accountCheckTicketsTransactionStatus', arguments);
            var headerParams = getHeaderParams(this, 'accountCheckTicketsTransactionStatus', arguments);
            var body = getBody(this, 'accountCheckTicketsTransactionStatus', arguments);
            var config = getApiMethodMetadata(this, 'accountCheckTicketsTransactionStatus', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/defaultAccount',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('refresh')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Boolean]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeGetDefaultAccount", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Accounts/{id}/performances/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeDestroyByIdPerformances", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/performances/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __param(2, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeUpdateByIdPerformances", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Accounts/{id}/venues/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeDestroyByIdVenues", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/venues/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __param(2, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeUpdateByIdVenues", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Accounts/{id}/events/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeDestroyByIdEvents", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/events/{fk}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('fk')),
            __param(2, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeUpdateByIdEvents", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/companyLogo',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('refresh')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Boolean]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeGetCompanyLogo", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/performances',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeGetPerformances", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/performances',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeCreatePerformances", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/performances/count',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('where')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeCountPerformances", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/venues',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeGetVenues", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/venues',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeCreateVenues", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/venues/count',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('where')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeCountVenues", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/events',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeGetEvents", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/events',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeCreateEvents", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/events/count',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('where')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountPrototypeCountEvents", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts',
            }),
            __param(0, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCreate", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{webKey}/clientInfo',
            }),
            __param(0, PathParam('webKey')),
            __param(1, Required('phoneNumber')),
            __param(1, QueryParam('phoneNumber')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetClientInfo", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{webKey}/accountInfo',
            }),
            __param(0, PathParam('webKey')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetAccountInfo", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{webKey}/inviteClient',
            }),
            __param(0, PathParam('webKey')),
            __param(1, Required('phoneNumber')),
            __param(1, QueryParam('phoneNumber')),
            __param(2, Required('requestId')),
            __param(2, QueryParam('requestId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountInviteClient", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{serverKey}/tickets',
            }),
            __param(0, PathParam('serverKey')),
            __param(1, Required('authorization')),
            __param(1, HeaderParam('authorization')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountOrderTickets", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{serverKey}/transaction/{transactionId}/confirm',
            }),
            __param(0, PathParam('serverKey')),
            __param(1, PathParam('transactionId')),
            __param(2, Required('authorization')),
            __param(2, HeaderParam('authorization')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountConfirmTransaction", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Accounts/{serverKey}/transaction/{transactionId}',
            }),
            __param(0, PathParam('serverKey')),
            __param(1, PathParam('transactionId')),
            __param(2, Required('authorization')),
            __param(2, HeaderParam('authorization')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCancelTransaction", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{serverKey}/transaction/{transactionId}',
            }),
            __param(0, PathParam('serverKey')),
            __param(1, PathParam('transactionId')),
            __param(2, Required('authorization')),
            __param(2, HeaderParam('authorization')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCheckTransactionStatus", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/backendVersion',
            }),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", []),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetBackendVersion", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/event/{eventId}/stats',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('eventId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetEventTicketStats", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{accountId}/addManager',
            }),
            __param(0, PathParam('accountId')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountAddManager", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{accountId}/addAgent',
            }),
            __param(0, PathParam('accountId')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountAddAgent", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/ticketType/{ticketTypeId}/sendPush',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketTypeId')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountSendPushByTicketType", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/ticketType/{ticketTypeId}/renameTicketHeaderFields',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketTypeId')),
            __param(2, Required('fieldMapping')),
            __param(2, Body('fieldMapping')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountRenameTicketTypeTicketHeaderFields", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/ticketType/{ticketTypeId}/renameTicketHeaderFieldsBulk',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('ticketTypeIdList')),
            __param(1, QueryParam('ticketTypeIdList')),
            __param(2, Required('fieldMapping')),
            __param(2, Body('fieldMapping')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountRenameTicketTypeTicketHeaderFieldsBulk", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/createTickets',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCreateTicket", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/ticket/{ticketId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketId')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountUpdateTicketById", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Accounts/{id}/ticket/{ticketId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountDeleteTicket", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/tickets',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('order')),
            __param(2, QueryParam('page')),
            __param(3, QueryParam('limit')),
            __param(4, QueryParam('ticketId')),
            __param(5, QueryParam('eventId')),
            __param(6, QueryParam('clientId')),
            __param(7, QueryParam('text')),
            __param(8, QueryParam('deliveryStatus')),
            __param(9, QueryParam('isConsumed')),
            __param(10, QueryParam('isDeleted')),
            __param(11, QueryParam('isConflicting')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, Number, Number, Number, Number, Number, String, String, Boolean, Boolean, Boolean]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetTickets", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/tickets/count',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('ticketId')),
            __param(2, QueryParam('eventId')),
            __param(3, QueryParam('clientId')),
            __param(4, QueryParam('text')),
            __param(5, QueryParam('deliveryStatus')),
            __param(6, QueryParam('isConsumed')),
            __param(7, QueryParam('isDeleted')),
            __param(8, QueryParam('isConflicting')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Number, Number, String, String, Boolean, Boolean, Boolean]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCountTickets", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/client/{clientId}/tickets',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('clientId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetTicketsByClient", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/ticket/{ticketId}/changeHolder',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketId')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountChangeTicketHolder", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/ticket/{ticketId}/resendSms',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountResendSms", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/ticket/{ticketId}/resendPush',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('ticketId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountResendPush", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/validateTickets',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountValidateTickets", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/clients',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('order')),
            __param(2, QueryParam('page')),
            __param(3, QueryParam('limit')),
            __param(4, QueryParam('clientId')),
            __param(5, QueryParam('text')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, Number, Number, Number, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetClients", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/clients/count',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('clientId')),
            __param(2, QueryParam('text')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCountClients", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/clientsToCall',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetClientsToCall", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/client/{clientId}/called',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('clientId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountClientCalled", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/clientByPhoneNumber',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('phoneNumber')),
            __param(1, QueryParam('phoneNumber')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetClientByPhoneNumber", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/client/{clientId}/approveEmail',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('clientId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountApproveClientEmail", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/client/{clientId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('clientId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetClient", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/client/{clientId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('clientId')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountUpdateClient", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/client/{clientId}/requestVerificationEmail',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('clientId')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountRequestVerificationEmail", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/agents',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetAgents", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/events/{eventId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('eventId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetAccountEvent", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/getevents',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('where')),
            __param(2, QueryParam('includeTicketCount')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, Boolean]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetAccountEvents", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/events/{eventId}/notify',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('eventId')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountNotifyClients", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/logs/{modelName}/{modelId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('modelName')),
            __param(2, PathParam('modelId')),
            __param(3, QueryParam('order')),
            __param(4, QueryParam('page')),
            __param(5, QueryParam('limit')),
            __param(6, QueryParam('ticketId')),
            __param(7, QueryParam('eventId')),
            __param(8, QueryParam('clientId')),
            __param(9, QueryParam('text')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, Number, String, Number, Number, Number, Number, Number, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetLogs", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/logs/{modelName}/{modelId}/count',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('modelName')),
            __param(2, PathParam('modelId')),
            __param(3, QueryParam('ticketId')),
            __param(4, QueryParam('eventId')),
            __param(5, QueryParam('clientId')),
            __param(6, QueryParam('text')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String, Number, Number, Number, Number, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCountLogs", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/venues/{venueId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('venueId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetVenue", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/getvenues',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('where')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetVenues", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/Accounts/{id}/venues/{venueId}/sections',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('venueId')),
            __param(2, Required('dto')),
            __param(2, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCreateSection", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/venues/{venueId}/sections',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('venueId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetSectionsByVenue", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/venues/{venueId}/sections/{sectionId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('venueId')),
            __param(2, PathParam('sectionId')),
            __param(3, Required('dto')),
            __param(3, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountUpdateSection", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/Accounts/{id}/venues/{venueId}/sections/{sectionId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('venueId')),
            __param(2, PathParam('sectionId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountDeleteSection", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/account',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetAccount", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/Accounts/{id}/account',
            }),
            __param(0, PathParam('id')),
            __param(1, Required('dto')),
            __param(1, Body('dto')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Object]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountUpdateAccount", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/tmevents',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetTmEvents", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/event/{eventId}/tmpricetypes',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('eventId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, Number]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountGetTmPriceTypes", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/Accounts/{id}/ticketsTransaction/{transactionId}',
            }),
            __param(0, PathParam('id')),
            __param(1, PathParam('transactionId')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Number, String]),
            __metadata("design:returntype", Promise)
        ], AccountApi.prototype, "accountCheckTicketsTransactionStatus", null);
        return AccountApi;
    }(BaseApi));
    API.AccountApi = AccountApi;
    ;
    /**
     * AppMessagingApi
     * @export
     */
    var AppMessagingApi = /** @class */ (function (_super) {
        __extends(AppMessagingApi, _super);
        function AppMessagingApi() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * @summary Create a new instance of the model and persist it into the data source.
         * @param {AppMessaging} [data] Model instance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingCreate = function (data) {
            var url = getFilledUrl(this, 'appMessagingCreate', arguments);
            var queryParams = getQueryParams(this, 'appMessagingCreate', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingCreate', arguments);
            var body = getBody(this, 'appMessagingCreate', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingCreate', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Patch an existing model instance or insert a new one into the data source.
         * @param {AppMessaging} [data] Model instance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingPatchOrCreate = function (data) {
            var url = getFilledUrl(this, 'appMessagingPatchOrCreate', arguments);
            var queryParams = getQueryParams(this, 'appMessagingPatchOrCreate', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingPatchOrCreate', arguments);
            var body = getBody(this, 'appMessagingPatchOrCreate', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingPatchOrCreate', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Replace an existing model instance or insert a new one into the data source.
         * @param {AppMessaging} [data] Model instance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingReplaceOrCreatePutAppMessagings = function (data) {
            var url = getFilledUrl(this, 'appMessagingReplaceOrCreatePutAppMessagings', arguments);
            var queryParams = getQueryParams(this, 'appMessagingReplaceOrCreatePutAppMessagings', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingReplaceOrCreatePutAppMessagings', arguments);
            var body = getBody(this, 'appMessagingReplaceOrCreatePutAppMessagings', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingReplaceOrCreatePutAppMessagings', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find all instances of the model matched by filter from the data source.
         * @param {string} [filter] Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingFind = function (filter) {
            var url = getFilledUrl(this, 'appMessagingFind', arguments);
            var queryParams = getQueryParams(this, 'appMessagingFind', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingFind', arguments);
            var body = getBody(this, 'appMessagingFind', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingFind', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Replace an existing model instance or insert a new one into the data source.
         * @param {AppMessaging} [data] Model instance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingReplaceOrCreatePostAppMessagingsReplaceOrCreate = function (data) {
            var url = getFilledUrl(this, 'appMessagingReplaceOrCreatePostAppMessagingsReplaceOrCreate', arguments);
            var queryParams = getQueryParams(this, 'appMessagingReplaceOrCreatePostAppMessagingsReplaceOrCreate', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingReplaceOrCreatePostAppMessagingsReplaceOrCreate', arguments);
            var body = getBody(this, 'appMessagingReplaceOrCreatePostAppMessagingsReplaceOrCreate', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingReplaceOrCreatePostAppMessagingsReplaceOrCreate', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
         * @param {string} [where] Criteria to match model instances
             * @param {AppMessaging} [data] An object of model property name/value pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingUpsertWithWhere = function (where, data) {
            var url = getFilledUrl(this, 'appMessagingUpsertWithWhere', arguments);
            var queryParams = getQueryParams(this, 'appMessagingUpsertWithWhere', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingUpsertWithWhere', arguments);
            var body = getBody(this, 'appMessagingUpsertWithWhere', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingUpsertWithWhere', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Check whether a model instance exists in the data source.
         * @param {string} id Model id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingExistsGetAppMessagingsIdExists = function (id) {
            var url = getFilledUrl(this, 'appMessagingExistsGetAppMessagingsIdExists', arguments);
            var queryParams = getQueryParams(this, 'appMessagingExistsGetAppMessagingsIdExists', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingExistsGetAppMessagingsIdExists', arguments);
            var body = getBody(this, 'appMessagingExistsGetAppMessagingsIdExists', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingExistsGetAppMessagingsIdExists', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Check whether a model instance exists in the data source.
         * @param {string} id Model id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingExistsHeadAppMessagingsId = function (id) {
            var url = getFilledUrl(this, 'appMessagingExistsHeadAppMessagingsId', arguments);
            var queryParams = getQueryParams(this, 'appMessagingExistsHeadAppMessagingsId', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingExistsHeadAppMessagingsId', arguments);
            var body = getBody(this, 'appMessagingExistsHeadAppMessagingsId', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingExistsHeadAppMessagingsId', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find a model instance by {{id}} from the data source.
         * @param {string} id Model id
             * @param {string} [filter] Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingFindById = function (id, filter) {
            var url = getFilledUrl(this, 'appMessagingFindById', arguments);
            var queryParams = getQueryParams(this, 'appMessagingFindById', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingFindById', arguments);
            var body = getBody(this, 'appMessagingFindById', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingFindById', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Replace attributes for a model instance and persist it into the data source.
         * @param {string} id Model id
             * @param {AppMessaging} [data] Model instance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingReplaceByIdPutAppMessagingsId = function (id, data) {
            var url = getFilledUrl(this, 'appMessagingReplaceByIdPutAppMessagingsId', arguments);
            var queryParams = getQueryParams(this, 'appMessagingReplaceByIdPutAppMessagingsId', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingReplaceByIdPutAppMessagingsId', arguments);
            var body = getBody(this, 'appMessagingReplaceByIdPutAppMessagingsId', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingReplaceByIdPutAppMessagingsId', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Delete a model instance by {{id}} from the data source.
         * @param {string} id Model id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingDeleteById = function (id) {
            var url = getFilledUrl(this, 'appMessagingDeleteById', arguments);
            var queryParams = getQueryParams(this, 'appMessagingDeleteById', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingDeleteById', arguments);
            var body = getBody(this, 'appMessagingDeleteById', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingDeleteById', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Patch attributes for a model instance and persist it into the data source.
         * @param {string} id AppMessaging id
             * @param {AppMessaging} [data] An object of model property name/value pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingPrototypePatchAttributes = function (id, data) {
            var url = getFilledUrl(this, 'appMessagingPrototypePatchAttributes', arguments);
            var queryParams = getQueryParams(this, 'appMessagingPrototypePatchAttributes', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingPrototypePatchAttributes', arguments);
            var body = getBody(this, 'appMessagingPrototypePatchAttributes', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingPrototypePatchAttributes', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Replace attributes for a model instance and persist it into the data source.
         * @param {string} id Model id
             * @param {AppMessaging} [data] Model instance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingReplaceByIdPostAppMessagingsIdReplace = function (id, data) {
            var url = getFilledUrl(this, 'appMessagingReplaceByIdPostAppMessagingsIdReplace', arguments);
            var queryParams = getQueryParams(this, 'appMessagingReplaceByIdPostAppMessagingsIdReplace', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingReplaceByIdPostAppMessagingsIdReplace', arguments);
            var body = getBody(this, 'appMessagingReplaceByIdPostAppMessagingsIdReplace', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingReplaceByIdPostAppMessagingsIdReplace', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Find first instance of the model matched by filter from the data source.
         * @param {string} [filter] Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingFindOne = function (filter) {
            var url = getFilledUrl(this, 'appMessagingFindOne', arguments);
            var queryParams = getQueryParams(this, 'appMessagingFindOne', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingFindOne', arguments);
            var body = getBody(this, 'appMessagingFindOne', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingFindOne', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Update instances of the model matched by {{where}} from the data source.
         * @param {string} [where] Criteria to match model instances
             * @param {AppMessaging} [data] An object of model property name/value pairs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingUpdateAll = function (where, data) {
            var url = getFilledUrl(this, 'appMessagingUpdateAll', arguments);
            var queryParams = getQueryParams(this, 'appMessagingUpdateAll', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingUpdateAll', arguments);
            var body = getBody(this, 'appMessagingUpdateAll', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingUpdateAll', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Count instances of the model matched by where from the data source.
         * @param {string} [where] Criteria to match model instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingCount = function (where) {
            var url = getFilledUrl(this, 'appMessagingCount', arguments);
            var queryParams = getQueryParams(this, 'appMessagingCount', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingCount', arguments);
            var body = getBody(this, 'appMessagingCount', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingCount', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Create a change stream.
         * @param {string} [options] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingCreateChangeStreamPostAppMessagingsChangeStream = function () {
            var url = getFilledUrl(this, 'appMessagingCreateChangeStreamPostAppMessagingsChangeStream', arguments);
            var queryParams = getQueryParams(this, 'appMessagingCreateChangeStreamPostAppMessagingsChangeStream', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingCreateChangeStreamPostAppMessagingsChangeStream', arguments);
            var body = getBody(this, 'appMessagingCreateChangeStreamPostAppMessagingsChangeStream', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingCreateChangeStreamPostAppMessagingsChangeStream', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        /**
         * @summary Create a change stream.
         * @param {string} [options] undefined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        AppMessagingApi.prototype.appMessagingCreateChangeStreamGetAppMessagingsChangeStream = function (options) {
            var url = getFilledUrl(this, 'appMessagingCreateChangeStreamGetAppMessagingsChangeStream', arguments);
            var queryParams = getQueryParams(this, 'appMessagingCreateChangeStreamGetAppMessagingsChangeStream', arguments);
            var headerParams = getHeaderParams(this, 'appMessagingCreateChangeStreamGetAppMessagingsChangeStream', arguments);
            var body = getBody(this, 'appMessagingCreateChangeStreamGetAppMessagingsChangeStream', arguments);
            var config = getApiMethodMetadata(this, 'appMessagingCreateChangeStreamGetAppMessagingsChangeStream', arguments);
            return axios.request({
                baseURL: this.baseURL,
                headers: __assign(__assign({}, headerParams), { authorization: this.authToken }),
                data: body,
                params: queryParams,
                url: url,
                method: config.method,
            })
                .then(function (x) { return x.data; })
                .catch(function (x) { return Promise.reject(x.response.data.error); });
        };
        ;
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/AppMessagings',
            }),
            __param(0, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingCreate", null);
        __decorate([
            ApiMethod({
                method: 'patch',
                url: '/AppMessagings',
            }),
            __param(0, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingPatchOrCreate", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/AppMessagings',
            }),
            __param(0, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingReplaceOrCreatePutAppMessagings", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/AppMessagings',
            }),
            __param(0, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingFind", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/AppMessagings/replaceOrCreate',
            }),
            __param(0, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingReplaceOrCreatePostAppMessagingsReplaceOrCreate", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/AppMessagings/upsertWithWhere',
            }),
            __param(0, QueryParam('where')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingUpsertWithWhere", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/AppMessagings/{id}/exists',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingExistsGetAppMessagingsIdExists", null);
        __decorate([
            ApiMethod({
                method: 'head',
                url: '/AppMessagings/{id}',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingExistsHeadAppMessagingsId", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/AppMessagings/{id}',
            }),
            __param(0, PathParam('id')),
            __param(1, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingFindById", null);
        __decorate([
            ApiMethod({
                method: 'put',
                url: '/AppMessagings/{id}',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingReplaceByIdPutAppMessagingsId", null);
        __decorate([
            ApiMethod({
                method: 'delete',
                url: '/AppMessagings/{id}',
            }),
            __param(0, PathParam('id')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingDeleteById", null);
        __decorate([
            ApiMethod({
                method: 'patch',
                url: '/AppMessagings/{id}',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingPrototypePatchAttributes", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/AppMessagings/{id}/replace',
            }),
            __param(0, PathParam('id')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingReplaceByIdPostAppMessagingsIdReplace", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/AppMessagings/findOne',
            }),
            __param(0, QueryParam('filter')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingFindOne", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/AppMessagings/update',
            }),
            __param(0, QueryParam('where')),
            __param(1, Body('data')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String, Object]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingUpdateAll", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/AppMessagings/count',
            }),
            __param(0, QueryParam('where')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingCount", null);
        __decorate([
            ApiMethod({
                method: 'post',
                url: '/AppMessagings/change-stream',
            }),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", []),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingCreateChangeStreamPostAppMessagingsChangeStream", null);
        __decorate([
            ApiMethod({
                method: 'get',
                url: '/AppMessagings/change-stream',
            }),
            __param(0, QueryParam('options')),
            __metadata("design:type", Function),
            __metadata("design:paramtypes", [String]),
            __metadata("design:returntype", Promise)
        ], AppMessagingApi.prototype, "appMessagingCreateChangeStreamGetAppMessagingsChangeStream", null);
        return AppMessagingApi;
    }(BaseApi));
    API.AppMessagingApi = AppMessagingApi;
    ;
})(API || (API = {}));
;
