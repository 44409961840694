var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { observable, action } from 'mobx';
import FlashMessage from 'models/FlashMessage';
var ContextStore = /** @class */ (function () {
    function ContextStore() {
        this.flashMessages = [];
        this.breadcrumb = [];
    }
    ContextStore.prototype.showMessage = function (message) {
        this.flashMessages.push(message);
    };
    ContextStore.prototype.removeMessage = function (id) {
        var index = this.flashMessages.findIndex(function (element) { return element.id === id; });
        this.flashMessages.splice(index);
    };
    ContextStore.prototype.setBreadcrumb = function (breadcrumb) {
        this.breadcrumb = __spreadArray([], breadcrumb, true);
    };
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], ContextStore.prototype, "flashMessages", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], ContextStore.prototype, "breadcrumb", void 0);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [FlashMessage]),
        __metadata("design:returntype", void 0)
    ], ContextStore.prototype, "showMessage", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ContextStore.prototype, "removeMessage", null);
    __decorate([
        action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], ContextStore.prototype, "setBreadcrumb", null);
    return ContextStore;
}());
export { ContextStore };
export default ContextStore;
