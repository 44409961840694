import phoneNumberLib, { PhoneNumberFormat } from 'google-libphonenumber';
import countries from './CountryCodes';
var phoneNumberUtil = phoneNumberLib.PhoneNumberUtil.getInstance();
var getDefaultCountryCodeForDialCode = function (dialCode) {
    var _a;
    var countriesForDialCode = countries.filter(function (c) { return c.dialCode === dialCode; });
    if (countriesForDialCode.length === 1) {
        return countriesForDialCode[0].code;
    }
    return (_a = countriesForDialCode.find(function (c) { return c.defaultCountryForDialCode === true; })) === null || _a === void 0 ? void 0 : _a.code;
};
var parsePhoneNumber = function (phoneNumber) {
    var _a;
    try {
        var parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
        var nationalNumber = ((_a = parsedPhoneNumber.getNationalNumber()) === null || _a === void 0 ? void 0 : _a.toString()) || '';
        var dialCode = phoneNumber.substr(0, phoneNumber.length - nationalNumber.length);
        var countryCode = getDefaultCountryCodeForDialCode(dialCode);
        return {
            nationalNumber: nationalNumber,
            countryCode: countryCode,
        };
    }
    catch (e) {
        return {
            nationalNumber: phoneNumber,
        };
    }
};
var getNationalNumber = function (phoneNumber, countryCode) {
    try {
        return phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(phoneNumber, countryCode), PhoneNumberFormat.E164);
    }
    catch (e) {
        return '';
    }
};
export var isValidPhoneNumber = function (phoneNumber) {
    try {
        var parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
        return phoneNumberUtil.isValidNumber(parsedPhoneNumber);
    }
    catch (e) {
        return false;
    }
};
export default {
    parsePhoneNumber: parsePhoneNumber,
    getNationalNumber: getNationalNumber,
};
